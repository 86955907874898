import { Post } from '../../../../../core/services/serviceBase';
import { ServiceOption } from '../../../../../core/options/serviceOption';
import {CityModel, RoomData} from "./models";

const API_URL = process.env.REACT_APP_API_URL
export const GET_CITY_URL = `${API_URL}/Support/GetCity`
export const ROOM_SEARCH_URL = `${API_URL}/Room/SearchRoom`
export const ROOM_QUERY_URL = `${API_URL}/Room/QueryRoom`
export const ROOM_UPDATE_URL = `${API_URL}/Room/UpdateRoom`
export const ROOM_CREATE_URL = `${API_URL}/Room/CreateRoom`
export const ROOM_DELETE_URL = `${API_URL}/Room/DeleteRoom`
export const ROOM_IMAGE_CREATE_URL = `${API_URL}/Room/CreateRoomImage`
export const ROOM_IMAGE_DELETE_URL = `${API_URL}/Room/DeleteRoomImage`

export async function getCity() {
    const response = await Post(GET_CITY_URL, {})
    let cityData = {} as Array<CityModel>;

    if(response?.responseCode === ServiceOption.Success){
        cityData = response.responseData;
    }

    return cityData
}

export async function searchRoom(
    roomName: string, 
    bedNumber: number,
    branchID: Array<number>) 
{
    const response = await Post(ROOM_SEARCH_URL, {
        roomName,
        bedNumber,
        branchID
    })
    let roomData = {} as Array<RoomData>;

    if(response?.responseCode === ServiceOption.Success){
        roomData = response.responseData;
    }

    return roomData;
}

export async function queryRoom(roomID: number) {
    const response = await Post(ROOM_QUERY_URL, { roomID })
    let roomData = {} as RoomData;

    if(response?.responseCode === ServiceOption.Success){
        roomData = response.responseData;
    }

    return roomData
}

export async function updateRoom(
    roomID: number,
    roomName: string,
    content: string,
    bedNumber: number,
    people: number,
    price: number,
    cityCode: string,
    branchID: number,
    phoneNumber: string,
    address: string,
    status: string,
    star: number,
    userRequest: number) {

    const response = await Post(ROOM_UPDATE_URL, { 
        roomID,
        roomName,
        content,
        bedNumber,
        people,
        price,
        cityCode,
        branchID,
        phoneNumber,
        address,
        status, 
        star,
        userRequest 
    })

    return response?.responseCode === ServiceOption.Success
}

export async function createRoom(
    roomName: string,
    content: string,
    bedNumber: number,
    people: number,
    price: number,
    cityCode: string,
    branchID: number,
    phoneNumber: string,
    address: string,
    status: string,
    star: number,
    userRequest: number) {

    const response = await Post(ROOM_CREATE_URL, {
        roomName,
        content,
        bedNumber,
        people,
        price,
        cityCode,
        branchID,
        phoneNumber,
        address,
        status, 
        star,
        userRequest 
    })

    let roomData = {} as RoomData;

    if(response?.responseCode === ServiceOption.Success){
        roomData = response.responseData;
    }

    return roomData
}

export async function deleteRoom(
    roomID: number,
    userRequest: number)
{
    const response = await Post(ROOM_DELETE_URL, {
        roomID,
        userRequest
    })
    
    return response?.responseCode === ServiceOption.Success
}

export async function createRoomImage(
    roomID: number,
    imageUrl: string,
    userRequest: number)
{
    const response = await Post(ROOM_IMAGE_CREATE_URL, {
        roomID,
        imageUrl,
        userRequest
    })
    
    return response?.responseCode === ServiceOption.Success
}

export async function deleteRoomImage(roomImageID: number)
{
    const response = await Post(ROOM_IMAGE_DELETE_URL, {
        roomImageID
    })
    
    return response?.responseCode === ServiceOption.Success
}