import axios from 'axios';
import { AuthModel, UserModel} from './models';
import { Post } from '../../../../core/services/serviceBase';
import { ServiceOption } from '../../../../core/options/serviceOption';

const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_URL = `${API_URL}/User/Login`
export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/User/GetToken`
export const REGISTER_URL = `${API_URL}/User/Register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

export async function login(userName: string, password: string) {
    const response = await Post(LOGIN_URL, { userName, password })
    let authData = {} as AuthModel;

    if(response?.responseCode === ServiceOption.Success){
        authData = response.responseData;
    }
    
    return authData;
}

export async function getUserByToken(token: string) {
    const response = await Post(GET_USER_BY_ACCESS_TOKEN_URL, { apiToken: token })
    let userData = {} as UserModel;
    
    if(response?.responseCode === ServiceOption.Success){
        userData = response.responseData;
    }
    
    return userData
}

export async function register(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    password_confirmation: string
) {

    let data = {
        userName: email,
        email: email,
        lastName: lastname,
        firstName: firstname,
        displayName: firstname + lastname,
        password: password,
        passwordConfirmation: password_confirmation
    }

    return await Post(REGISTER_URL, data)
}

export function requestPassword(email: string) {
    return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
        email,
    })
}
