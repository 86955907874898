import { Post } from '../../../../../core/services/serviceBase';
import { ServiceOption } from '../../../../../core/options/serviceOption';
import { CityModel, ServiceData } from './models';

const API_URL = process.env.REACT_APP_API_URL
export const GET_CITY_URL = `${API_URL}/Support/GetCity`
export const SERVICE_SEARCH_URL = `${API_URL}/Service/SearchService`
export const SERVICE_QUERY_URL = `${API_URL}/Service/QueryService`
export const SERVICE_UPDATE_URL = `${API_URL}/Service/UpdateService`
export const SERVICE_CREATE_URL = `${API_URL}/Service/CreateService`
export const SERVICE_DELETE_URL = `${API_URL}/Service/DeleteService`
export const SERVICE_IMAGE_CREATE_URL = `${API_URL}/Service/CreateServiceImage`
export const SERVICE_IMAGE_DELETE_URL = `${API_URL}/Service/DeleteServiceImage`

export async function getCity() {
    const response = await Post(GET_CITY_URL, {})
    let cityData = {} as Array<CityModel>;

    if(response?.responseCode === ServiceOption.Success){
        cityData = response.responseData;
    }

    return cityData
}

export async function searchService(
    serviceName: string, 
    phoneNumber: string,
    branchID: Array<number>) 
{
    const response = await Post(SERVICE_SEARCH_URL, {
        serviceName,
        phoneNumber,
        branchID
    })
    let roomData = {} as Array<ServiceData>;

    if(response?.responseCode === ServiceOption.Success){
        roomData = response.responseData;
    }

    return roomData;
}

export async function queryService(serviceID: number) {
    const response = await Post(SERVICE_QUERY_URL, { serviceID })
    let roomData = {} as ServiceData;

    if(response?.responseCode === ServiceOption.Success){
        roomData = response.responseData;
    }

    return roomData
}

export async function updateService(
    serviceID: number,
    serviceName: string,
    content: string,
    price: number,
    cityCode: string,
    branchID: number,
    phoneNumber: string,
    address: string,
    status: string,
    userRequest: number) {

    const response = await Post(SERVICE_UPDATE_URL, { 
        serviceID,
        serviceName,
        content,
        price,
        cityCode,
        branchID,
        phoneNumber,
        address,
        status, 
        userRequest 
    })

    return response?.responseCode === ServiceOption.Success
}

export async function createService(
    serviceName: string,
    content: string,
    price: number,
    cityCode: string,
    branchID: number,
    phoneNumber: string,
    address: string,
    status: string,
    userRequest: number) {

    const response = await Post(SERVICE_CREATE_URL, {
        serviceName,
        content,
        price,
        cityCode,
        branchID,
        phoneNumber,
        address,
        status, 
        userRequest 
    })

    let roomData = {} as ServiceData;

    if(response?.responseCode === ServiceOption.Success){
        roomData = response.responseData;
    }

    return roomData
}

export async function deleteService(
    serviceID: number,
    userRequest: number)
{
    const response = await Post(SERVICE_DELETE_URL, {
        serviceID,
        userRequest
    })
    
    return response?.responseCode === ServiceOption.Success
}

export async function createServiceImage(
    serviceID: number,
    imageUrl: string,
    userRequest: number)
{
    const response = await Post(SERVICE_IMAGE_CREATE_URL, {
        serviceID,
        imageUrl,
        userRequest
    })
    
    return response?.responseCode === ServiceOption.Success
}

export async function deleteServiceImage(serviceImageID: number)
{
    const response = await Post(SERVICE_IMAGE_DELETE_URL, {
        serviceImageID
    })
    
    return response?.responseCode === ServiceOption.Success
}