import React, {FC, useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../auth";
import {GridColumns} from "@mui/x-data-grid";
import {KTSVG} from "../../../../_metronic/helpers";
import StripedDataGrid from '../../../../core/components/data-grid/StripedDataGridPage';
import CustomPagination from "../../../../core/components/data-grid/PaginationPage";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import { toast } from 'react-toastify';
import { ToastMessageOption } from '../../../../core/options/toastMessageOption';
import { OrderData } from './core/models';
import { queryOrder, searchOrder, updateOrder } from './core/service';
import { DateTimeToISOstring, FormatDateTimeToLong, IsEmpty } from '../../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';
import { AccountStatusWidget, PaymentStatusWidget } from '../../../../core/widgets/AccountStatusWidget';

const listOrderType = [
    { label: 'Room', value: 'ROOM' },
    { label: 'Service', value: 'SERVICE' }
]

const listPaymentStatus = [
    { label: 'Have not paid', value: 'INIT' },
    { label: 'Paid', value: 'PAID' }
]

const listStatus = [
    { label: 'New', value: 'NEW' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Blocked', value: 'BLOCKED' },
    { label: 'Delete', value: 'DELETE' }
]

const OrderPage: FC = () => {
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const subBranch = currentUser?.subBranch

    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Booking',
            path: '/book/order-search',
            isSeparator: false,
            isActive: false,
        }
    ]
    const columnRoleGroups: GridColumns = [
        {field: 'orderID', width: 50, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <button className="border-0 rounded bg-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_order"
                        onClick={() => {bntPopupOrder(params.value)}}>
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3' />
                </button>
            }
        },
        {field: 'orderReferenceName', width: 200, editable: true, renderHeader: () => (<strong>Name</strong>)},
        {field: 'orderType', width: 100, editable: true, renderHeader: () => (<strong>Booking type</strong>)},
        {field: 'orderNumber', width: 150, editable: true, renderHeader: () => (<strong>Booking number</strong>)},
        {field: 'displayOrderTotalAmount', width: 150, editable: true, renderHeader: () => (<strong>Total amount</strong>)},
        {field: 'fullName', width: 200, editable: true, renderHeader: () => (<strong>Customer name</strong>)},
        {field: 'phoneNumber', width: 200, editable: true, renderHeader: () => (<strong>Phone Number</strong>)},
        {field: 'email', width: 200, editable: true, renderHeader: () => (<strong>Email</strong>)},
        {field: 'dateCheckIn', width: 200, editable: true, renderHeader: () => (<strong>Date check-in</strong>)},
        {field: 'dateCheckOut', width: 200, editable: true, renderHeader: () => (<strong>Date check-out</strong>)},
        {field: 'paymentMethod', width: 150, editable: true, renderHeader: () => (<strong>Payment methos</strong>)},
        {field: 'paymentStatus', width: 150, editable: true, renderHeader: () => (<strong>Payment status</strong>), renderCell: 
            (params) => {return PaymentStatusWidget(params.value)}},
        {field: 'transactionID', width: 250, editable: true, renderHeader: () => (<strong>TransactionID</strong>)},
        {field: 'status', width: 100, editable: true, renderHeader: () => (<strong>Status</strong>), renderCell: 
            (params) => {return AccountStatusWidget(params.value)}},
        {field: 'dateTimeModify', width: 200, editable: true, renderHeader: () => (<strong>Date time modify</strong>)}
    ];
    const [orderData, setOrderData] = React.useState<Array<OrderData>>();
    const [txtOrderNumber, setTxtOrderNumber] = useState('')
    const [txtUser, setTxtUser] = useState('');
    const [txtOrderType, setTxtOrderType] = useState('');
    const [txtPaymentStatus, setTxtPaymentStatus] = useState('');
    const [txtFromDateTime, setTxtFromDateTime] = useState(DateTimeToISOstring(7));
    const [txtToDateTime, setTxtToDateTime] = useState(DateTimeToISOstring(-1));
    const [loadingButtonSearch, setLoadingButtonSearch] = useState(false)
    const [loadingButtonUpdate, setLoadingButtonUpdate] = useState(false)

    const [orderID, setOrderID] = useState(0)
    const [orderReferenceName, setOrderReferenceName] = useState('')
    const [orderType, setOrderType] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [displayOrderTotalAmount, setDisplayOrderTotalAmount] = useState('')
    const [fullName, setFullName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [dateCheckIn, setDateCheckIn] = useState('')
    const [dateCheckOut, setDateCheckOut] = useState('')
    const [description, setDescription] = useState('')
    const [count, setCount] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')
    const [transactionID, setTransactionID] = useState('')
    const [status, setStatus] = useState('')
    const [updatePaymnetStatus, setUpdatePaymentStatus] = useState('')
    const [dateTimeModify, setDateTimeModify] = useState('')
    const [userIDModify, setUserIDModify] = useState('')
    const [bookingRole, setBookingRole] = React.useState(false);

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setBookingRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_BOOKING')));
        }
    },[])
    
    const search = () => {
        if(subBranch){
            setLoadingButtonSearch(true)
            searchOrder(
                txtOrderNumber,
                txtUser,
                txtOrderType,
                txtPaymentStatus,
                FormatDateTimeToLong(new Date(txtFromDateTime)), 
                FormatDateTimeToLong(new Date(txtToDateTime)), 
                subBranch.map(item => item.branchID)
            ).then((response) => {
                setOrderData(response)
                setLoadingButtonSearch(false)
            });
        }
    }

    const bntPopupOrder = (orderID: number) => {
        if(subBranch){
            queryOrder(orderID, subBranch.map(item => item.branchID)).then((response) => {
                setOrderID(response.orderID)
                setOrderReferenceName(response.orderReferenceName)
                setOrderType(response.orderType)
                setOrderNumber(response.orderType)
                setDisplayOrderTotalAmount(response.displayOrderTotalAmount)
                setPhoneNumber(response.phoneNumber)
                setFullName(response.fullName)
                setDateCheckIn(response.dateCheckIn)
                setDateCheckOut(response.dateCheckOut)
                setDescription(response.description)
                setCount(response.count)
                setPaymentMethod(response.paymentMethod)
                setPaymentStatus(response.paymentStatus)
                setUpdatePaymentStatus(response.paymentStatus)
                setTransactionID(response.transactionID)
                setStatus(response.status)
                setDateTimeModify(response.dateTimeModify)
                setUserIDModify(response.userIDModify)
            });
        }
    }

    const update = () => {
        if(subBranch && orderID > 0){
            setLoadingButtonUpdate(true)
            updateOrder(orderID, status, updatePaymnetStatus, UserID || 0).then((response) => {
                if(response) {
                    toast.success(ToastMessageOption.Success)
                    search()
                } else {
                    toast.error(ToastMessageOption.Error)
                }
                setLoadingButtonUpdate(false)
            });
        }
    }

    return (
        <>
            { bookingRole ? (
                <>
                    <PageTitle breadcrumbs={usersBreadcrumbs}></PageTitle>
                    <div className='card mb-5 mb-xl-10'>
                        <div id='kt_branch_data' className='collapse show'>
                            <div className='card-body border-top p-9'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                        <div className='row mb-1'>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                        className="form-label">Booking number</label>
                                                <input type="text"
                                                        onChange={(e) => {
                                                            setTxtOrderNumber(e.target.value)
                                                        }}
                                                    className="form-control form-control-solid"
                                                    placeholder="Book number"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                        className="form-label">Email or Phone number</label>
                                                <input type="text"
                                                        onChange={(e) => {
                                                            setTxtUser(e.target.value)
                                                        }}
                                                    className="form-control form-control-solid"
                                                    placeholder="Email or Phone number"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblBedNumber'
                                                        className='form-label fs-6'>Booking type</label>
                                                    <select className='form-select form-select-solid'
                                                            onChange={(e) => {
                                                                setTxtOrderType(e.target.value)}
                                                            }>
                                                        <option value='' >Select all</option>
                                                        {listOrderType?.map((item) =>
                                                            <option key={item.value} value={item.value} >
                                                                {item.label}
                                                            </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-1 mt-10'>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblBedNumber'
                                                        className='form-label fs-6'>Payment Status</label>
                                                    <select className='form-select form-select-solid'
                                                            onChange={(e) => {
                                                                setTxtPaymentStatus(e.target.value)}
                                                            }>
                                                        <option value='' >Select all</option>
                                                        {listPaymentStatus?.map((item) =>
                                                            <option key={item.value} value={item.value} >
                                                                {item.label}
                                                            </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">From Date time</label>
                                                <input type="datetime-local"
                                                        value={txtFromDateTime}
                                                        onChange={(e) => {setTxtFromDateTime(e.target.value)}}
                                                        className="form-control form-control-solid"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">To Date time</label>
                                                <input type="datetime-local"
                                                        value={txtToDateTime}
                                                        onChange={(e) => {setTxtToDateTime(e.target.value)}}
                                                        className="form-control form-control-solid"/>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-5'>
                                            <div className='mx-auto'>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    onClick={search}
                                                    className='btn btn-primary me-2'
                                                >
                                                    {!loadingButtonSearch && 'Search'}
                                                    {loadingButtonSearch && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        {orderData && (
                                            <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                                <StripedDataGrid
                                                    loading={false}
                                                    rows={orderData}
                                                    columns={columnRoleGroups}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                    }
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    components={{
                                                        Pagination: CustomPagination,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Model */}
                    <div className="modal bg-white fade" tabIndex={-1} id="kt_modal_order">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content shadow-none">
                                <div className="modal-header">
                                    <h5 className="modal-title">Information Booking</h5>
                                    <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr061.svg"
                                            className="svg-icon svg-icon-2x"
                                        />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Name</label>
                                            <input type="text"
                                                    readOnly
                                                    value={orderReferenceName}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Booking type</label>
                                            <input type="text"
                                                    readOnly
                                                    value={orderType}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Booking number</label>
                                            <input type="text"
                                                    readOnly
                                                    value={orderNumber}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                    </div>
                                    <div className="row mt-10">
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Customer name</label>
                                            <input type="text"
                                                    readOnly
                                                    value={fullName}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Phone number</label>
                                            <input type="text"
                                                    readOnly
                                                    value={phoneNumber}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Email</label>
                                            <input type="text"
                                                    readOnly
                                                    value={phoneNumber}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                    </div>
                                    <div className="row mt-10">
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Total amount</label>
                                            <input type="text"
                                                    readOnly
                                                    value={displayOrderTotalAmount}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Check-in</label>
                                            <input type="text"
                                                    readOnly
                                                    value={dateCheckIn}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Check-out</label>
                                            <input type="text"
                                                    readOnly
                                                    value={dateCheckOut}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                    </div>
                                    <div className="row mt-10">
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Count</label>
                                            <input type="text"
                                                    readOnly
                                                    value={count}
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-8'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Description</label>
                                            <textarea className="form-control form-control-solid"
                                                    readOnly
                                                    value={description}/>
                                        </div>
                                    </div>
                                    <div className="row mt-10">
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Payment method</label>
                                            <input type="text"
                                                    value={paymentMethod}
                                                    readOnly
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Payment status <span style={{color: 'red'}}>*</span></label>
                                            <select className='form-select form-select-solid'
                                                    value={updatePaymnetStatus}
                                                    disabled={paymentStatus === 'PAID'}
                                                    onChange={(e) => {
                                                        setUpdatePaymentStatus(e.target.value)}
                                                    }>
                                                    {listPaymentStatus?.map((item) =>
                                                        <option key={item.value} value={item.value} >
                                                            {item.label}
                                                        </option>)
                                                    }
                                            </select>    
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">TransactionID</label>
                                            <input type="text"
                                                    value={transactionID}
                                                    readOnly
                                                    className="form-control form-control-solid"/>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Status <span style={{color: 'red'}}>*</span></label>
                                            <select className='form-select form-select-solid'
                                                value={status}
                                                onChange={(e) => {
                                                    setStatus(e.target.value)}
                                                }>
                                                {listStatus?.map((item) =>
                                                    <option key={item.value} value={item.value} >
                                                        {item.label}
                                                    </option>)
                                                }
                                            </select>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Date time modify</label>
                                            <input type="text"
                                                    value={dateTimeModify}
                                                    readOnly
                                                    className="form-control form-control-solid"/>
                                        </div>
                                        <div className='col-lg-4'>
                                            <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">User modify</label>
                                            <input type="text"
                                                    value={userIDModify}
                                                    readOnly
                                                    className="form-control form-control-solid"/>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="modal-footer">
                                    <button
                                        id="closeButtonBranch"
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button type="button" onClick={update} className="btn btn-primary">
                                        {!loadingButtonUpdate && 'Save'}
                                        {loadingButtonUpdate && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}
            
        </>
    )
}

export {OrderPage}

