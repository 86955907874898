import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/service'
import { useAuth } from '../core/Auth'
import { IsEmpty } from '../../../../core/utilities/functionBase'

const loginSchema = Yup.object().shape({
    email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
    password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
    email: 'host@email.com',
    password: 'qqq111!!!',
}

export function Login() {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const auth = await login(values.email, values.password)
                const user = await getUserByToken(auth.apiToken)
    
                if(IsEmpty(auth) || IsEmpty(user)){
                saveAuth(undefined)
                setCurrentUser(undefined)
                setStatus('The login detail is incorrect')
                setSubmitting(false)
                setLoading(false)
            } else {
                saveAuth(auth)
                setCurrentUser(user)
            }
            } 
            catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The login detail is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
    },})

    return (
        <form className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Sign In to Portal Phu Hoa</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                    New Here?{' '}
                    <Link to='/auth/registration' className='link-primary fw-bolder'>
                        Create an Account
                    </Link>
                </div>
            </div>
            {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                <div></div>
            )}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx('form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {'is-valid': formik.touched.email && !formik.errors.email,})}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                    </div>
                </div>
                <input type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx('form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.password && formik.errors.password,},
                    {'is-valid': formik.touched.password && !formik.errors.password,})}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
    )
}
