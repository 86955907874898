import {AuthModel} from './models'
import {Logout} from "../Logout";
import { FormatDateTimeToLong, GetDateTime } from '../../../../core/utilities/functionBase';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }
    
    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            if (Number(auth.expireTime) <= FormatDateTimeToLong(new Date())){
                console.log('Expired ' + auth.expireTime);
                removeAuth();
                window.location.reload();
                return;
            } else {
                var date = new Date();
                date.setMinutes(date.getMinutes()+ 15);
                let expireTime = FormatDateTimeToLong(date);
                auth.expireTime = expireTime.toString();
                console.log('Time Expire ' + auth.expireTime);

                setAuth(auth);
                return auth
            }
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setAuth = (auth: AuthModel) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const removeAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use((config: {headers: {Authorization: string}}) => {
        const auth = getAuth()
        if (auth && auth.apiToken) {
            //config.headers.Authorization = `Bearer ${auth.apiToken}`
        }
    
        return config
    },
    (err: any) => Promise.reject(err))
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
