import { Post } from '../../../../core/services/serviceBase';
import { ServiceOption } from '../../../../core/options/serviceOption';
import { CalendarData, ReportData } from './model';

const API_URL = process.env.REACT_APP_API_URL
export const GET_CITY_URL = `${API_URL}/Support/GetCity`
export const GET_CALENDAR_URL = `${API_URL}/Report/GetCalendar`
export const GET_REPORT_URL = `${API_URL}/Report/GetReport`

export async function getCalendarData(
    branchID: number,
    month: string) 
{
    const response = await Post(GET_CALENDAR_URL, {
        branchID,
        month
    })
    let calendarData = {} as Array<CalendarData>;

    if(response?.responseCode === ServiceOption.Success){
        calendarData = response.responseData;
    }

    return calendarData;
}

export async function getReportData(
    branchID: Array<number>) 
{
    const response = await Post(GET_REPORT_URL, {branchID})
    let reportData = {} as ReportData;

    if(response?.responseCode === ServiceOption.Success){
        reportData = response.responseData;
    }

    return reportData;
}