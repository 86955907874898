import { ServiceOption } from '../../../../../core/options/serviceOption';
import {Get, Post} from '../../../../../core/services/serviceBase';
import {RoleData, RoleGroupData} from "./models";

const API_URL = process.env.REACT_APP_API_URL
export const SEARCH_ROLE_URL = `${API_URL}/Role/Search`
export const QUERY_ROLE_URL = `${API_URL}/Role/Query`
export const SAVE_ROLE_URL = `${API_URL}/Role/Save`

export const GET_ROLE_GROUP_URL = `${API_URL}/Role/GetRoleGroup`
export const SEARCH_ROLE_GROUP_URL = `${API_URL}/Role/SearchRoleGroup`
export const QUERY_ROLE_GROUP_URL = `${API_URL}/Role/QueryRoleGroup`
export const SAVE_ROLE_GROUP_URL = `${API_URL}/Role/SaveRoleGroup`

export async function SearchRole(roleName: string) {
    const response = await Post(SEARCH_ROLE_URL, {
        roleName
    })
    let roles = {} as Array<RoleData>;

    if(response?.responseCode === ServiceOption.Success){
        roles = response.responseData;
    }

    return roles;
}

export async function QueryRole(roleId: string) {
    const response = await Post(QUERY_ROLE_URL, {
        roleId
    })
    let role = {} as RoleData;

    if(response?.responseCode === ServiceOption.Success){
        role = response.responseData;
    }

    return role;
}

export async function SaveRole(
    roleId: string, 
    roleGroupID: string, 
    roleName: string, 
    description: string, 
    isDisable = false,
    userID: number | undefined) {
    const response = await Post(SAVE_ROLE_URL, {
        roleId,
        roleGroupID,
        roleName,
        description,
        isDisable,
        userID
    })

    return response?.responseCode === ServiceOption.Success
}

export async function SearchRoleGroup(roleGroupName: string) {
    const response = await Post(SEARCH_ROLE_GROUP_URL, {
        roleGroupName
    })
    let authData = {} as Array<RoleGroupData>;

    if(response?.responseCode === ServiceOption.Success){
        authData = response.responseData;
    }

    return authData;
}

export async function QueryRoleGroup(roleGroupId: string) {
    const response = await Post(QUERY_ROLE_GROUP_URL, {
        roleGroupId
    })
    let roleGroup = {} as RoleGroupData;

    if(response?.responseCode === ServiceOption.Success){
        roleGroup = response.responseData;
    }

    return roleGroup;
}

export async function GetRoleGroup() {
    const response = await Get(GET_ROLE_GROUP_URL)
    let authData = {} as Array<RoleGroupData>;

    if(response?.responseCode === ServiceOption.Success){
        authData = response.responseData;
    }

    return authData;
}

export async function SaveRoleGroup(
    roleGroupId: string, 
    roleGroupName: string, 
    description: string, 
    isDisable = false,
    userID: number | undefined) {
    const response = await Post(SAVE_ROLE_GROUP_URL, {
        roleGroupId,
        roleGroupName,
        description,
        isDisable,
        userID
    })

    return response?.responseCode === ServiceOption.Success
}