import React, {FC, useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../auth";
import {GridColumns} from "@mui/x-data-grid";
import {KTSVG} from "../../../_metronic/helpers";
import {CityModel, TouristPlaceData} from "./core/models";
import StripedDataGrid from '../../../core/components/data-grid/StripedDataGridPage';
import CustomPagination from "../../../core/components/data-grid/PaginationPage";
import {
    getCity,
    searchTouristPlaces
} from "./core/service";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import { Link, useNavigate } from 'react-router-dom';
import { IsEmpty } from '../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';


const TouristPlacePage: FC = () => {
    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Tourist Places',
            path: '/support/tourist-places',
            isSeparator: false,
            isActive: false,
        }
    ]
    const {currentUser} = useAuth()
    const navigate = useNavigate();
    const columnRoleGroups: GridColumns = [
        {field: 'touristPlaceID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <Link to={`/support/tourist-place-inquiry/${params.value}`}>
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3'/>
                </Link>
            }
        },
        {field: 'touristPlaceName', width: 150, editable: true, renderHeader: () => (<strong>Name</strong>)},
        {field: 'content', width: 300, editable: true, renderHeader: () => (<strong>Content</strong>)},
        {field: 'cityCode', width: 150, editable: true, renderHeader: () => (<strong>City Code</strong>)},
        {field: 'phoneNumber', width: 200, editable: true, renderHeader: () => (<strong>Phone Number</strong>)},
        {field: 'address', width: 300, editable: true, renderHeader: () => (<strong>Address</strong>)},
        {field: 'dateTimeCreate', width: 200, editable: true, renderHeader: () => (<strong>Create Date time</strong>)}
    ];
    const [cityData, setCityData] = React.useState<Array<CityModel>>();

    const [loadingButtonSearch, setLoadingButtonSearch] = useState(false)
    const [txtCity, setTxtCity] = useState('');
    const [txtTouristPlacce, setTxtTouristPlace] = useState('');
    const [touristPlaceData, setTouristPlaceData] = React.useState<Array<TouristPlaceData>>();
    const [supportRole, setSupportRole] = React.useState(false);

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setSupportRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_SUPPORTS')));
        }

        getCity().then((response) => {
            setCityData(response)
        })
    },[])

    const search = () => {
        setLoadingButtonSearch(true)
        searchTouristPlaces(
            txtTouristPlacce,
            txtCity
        ).then((response) => {
            setTouristPlaceData(response)
            setLoadingButtonSearch(false)
        });
    }

    const create = () => {
        navigate("/support/tourist-place-inquiry/0");
    }

    return (
        <>
            { supportRole ? (
                <>
                    <PageTitle breadcrumbs={usersBreadcrumbs}></PageTitle>
                    <div className='card mb-5 mb-xl-10'>
                        <div id='kt_branch_data' className='collapse show'>
                            <div className='card-body border-top p-9'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                        <div className='row mb-1'>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Tourist place name</label>
                                                <input type="text"
                                                    onChange={(e) => {
                                                            setTxtTouristPlace(e.target.value)
                                                    }}
                                                    className="form-control form-control-solid"
                                                    placeholder="Tourist place name"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblCity'
                                                        className='form-label fs-6'>City</label>
                                                    <select className='form-select form-select-solid'
                                                            onChange={(e) => {
                                                                setTxtCity(e.target.value)}
                                                            }>
                                                        <option value='' >Select all</option>
                                                        {cityData?.map((item) =>
                                                            <option key={item.cityID} value={item.cityCode} >
                                                                {item.cityCode} - {item.cityName}
                                                            </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-5'>
                                            <div className='mx-auto'>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    onClick={search}
                                                    className='btn btn-primary me-2'
                                                >
                                                    {!loadingButtonSearch && 'Search'}
                                                    {loadingButtonSearch && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    className='btn btn-success me-2'
                                                    onClick={() => create()}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                        {touristPlaceData && (
                                            <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                                <StripedDataGrid
                                                    loading={false}
                                                    rows={touristPlaceData}
                                                    columns={columnRoleGroups}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                    }
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    components={{
                                                        Pagination: CustomPagination,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : ( 
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}
        </>
    )
}

export {TouristPlacePage}
