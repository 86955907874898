import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {forwardRef, useImperativeHandle, useState} from "react";
import { LoadingPageData } from '../models/baseData';

const LoadingPage = forwardRef<LoadingPageData, {}>((props, ref) => {
    const [open, setOpen] = useState(false);
    useImperativeHandle(
        ref,
        () => ({
            handleClose(){
                setOpen(false);
            },
            handleToggle(){
                setOpen(!open);
            }
        }),
    )
    
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
});

export {LoadingPage}