import { Post } from '../../../../../core/services/serviceBase';
import { ServiceOption } from '../../../../../core/options/serviceOption';
import { OrderData } from './models';

const API_URL = process.env.REACT_APP_API_URL
export const BOOK_SEARCH_URL = `${API_URL}/Order/SearchOrders`
export const BOOK_QUERY_URL = `${API_URL}/Order/QueryOrder`
export const BOOK_UPDATE_URL = `${API_URL}/Order/UpdateOrder`


export async function searchOrder(
    orderNumber: string, 
    user: string,
    orderType: string,
    paymentStatus: string,
    fromDate: number,
    toDate: number,
    branchID: Array<number>) 
{
    const response = await Post(BOOK_SEARCH_URL, {
        orderNumber,
        user,
        orderType,
        paymentStatus,
        fromDate,
        toDate,
        branchID
    })
    let orderData = {} as Array<OrderData>;

    if(response?.responseCode === ServiceOption.Success){
        orderData = response.responseData;
    }

    return orderData;
}

export async function queryOrder(orderID: number, branchID: Array<number>) {
    const response = await Post(BOOK_QUERY_URL, { orderID, branchID })
    let orderData = {} as OrderData;

    if(response?.responseCode === ServiceOption.Success){
        orderData = response.responseData;
    }

    return orderData
}

export async function updateOrder(
    orderID: number,
    status: string,
    paymentStatus: string,
    userRequest: number) {

    const response = await Post(BOOK_UPDATE_URL, { 
        orderID,
        status,
        paymentStatus,
        userRequest 
    })

    return response?.responseCode === ServiceOption.Success
}