import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/service'
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import { IsEmpty } from '../../../../core/utilities/functionBase'
import {AuthModel} from "../core/models";
import {ServiceOption} from "../../../../core/options/serviceOption";
import {RequestServiceData, ResponseServiceData} from "../../../../core/models/serviceData";

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading( true)
        try {
            let responseData: ResponseServiceData | null;
            responseData = await register(
                values.email,
                values.firstname,
                values.lastname,
                values.password,
                values.changepassword
            )
              
            let authData = {} as AuthModel;
            if(!responseData || IsEmpty(responseData) || responseData?.responseCode === ServiceOption.BadRequest){
                let message = responseData?.responseMessage 
                    ? responseData?.responseMessage 
                    : 'The registration details is incorrect'
                saveAuth(undefined)
                setCurrentUser(undefined)
                setStatus(message)
                setSubmitting(false)
                setLoading(false)
                return;
            }

            authData = responseData.responseData;
            const user = await getUserByToken(authData.apiToken)
            if(IsEmpty(authData)){
                saveAuth(undefined)
                setCurrentUser(undefined)
                setStatus('The registration details is incorrect')
                setSubmitting(false)
                setLoading(false)
            } else {
                  saveAuth(authData)
                  setCurrentUser(user)
            }
        } catch (error) {
            console.error(error)
            saveAuth(undefined)
            setStatus('The registration details is incorrect')
            setSubmitting(false)
            setLoading(false)
        }
    },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='mb-10 text-center'>
                <h1 className='text-dark mb-3'>Create an Account</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                    Already have an account?
                <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
                    Login
                </Link>
            </div>
            </div>
            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}
            
            <div className='row fv-row mb-7'>
                <div className='col-xl-6'>
                    <label className='form-label fw-bolder text-dark fs-6'>First name</label>
                    <input placeholder='First name' 
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('firstname')}
                        className={clsx('form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.firstname && formik.errors.firstname,}, 
                        {'is-valid': formik.touched.firstname && !formik.errors.firstname,}
                    )}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.firstname}</span>
                    </div>
                </div>
                )}
            </div>
            <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                <input placeholder='Last name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastname')}
                    className={clsx('form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.lastname && formik.errors.lastname,},
                    {'is-valid': formik.touched.lastname && !formik.errors.lastname,}
                )}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.lastname}</span>
                        </div>
                    </div>
                )}
            </div>
            </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                    <input placeholder='Email'
                        type='email'
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx('form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {'is-valid': formik.touched.email && !formik.errors.email,}
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                    <div className='position-relative mb-3'>
                        <input type='password' 
                            placeholder='Password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx('form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.password && formik.errors.password,},
                            {'is-valid': formik.touched.password && !formik.errors.password,}
                        )}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>
            <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            </div>
                <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
            </div>
            
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <input type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                 className={clsx('form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.changepassword && formik.errors.changepassword,},
                {'is-valid': formik.touched.changepassword && !formik.errors.changepassword,}
                )}
            />{formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.changepassword}</span>
                    </div>
                </div>
            )}
            </div>
            <div className='fv-row mb-10'>
                <div className='form-check form-check-custom form-check-solid'>
                    <input className='form-check-input'
                        type='checkbox'
                        id='kt_login_toc_agree'
                        {...formik.getFieldProps('acceptTerms')}
                    />
                    <label className='form-check-label fw-bold text-gray-700 fs-6'
                        htmlFor='kt_login_toc_agree'>
                        I Agree.
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.acceptTerms}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='text-center'>
            <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </button>
            <Link to='/auth/login'>
                <button
                    type='button'
                    id='kt_login_signup_form_cancel_button'
                    className='btn btn-lg btn-light-primary w-100 mb-5'
                >
                    Cancel
                </button>
                </Link>
            </div>
        </form>
    )
}
