import React, {FC, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {BranchData} from "../core/models";
import {KTSVG} from "../../../../../_metronic/helpers";
import CustomPagination from "../../../../../core/components/data-grid/PaginationPage";
import StripedDataGrid from "../../../../../core/components/data-grid/StripedDataGridPage";
import {GridColumns} from "@mui/x-data-grid";
import {SaveBranch, GetBranches, QueryBranch, SearchBranch} from "../core/service";
import {ToastMessageOption} from "../../../../../core/options/toastMessageOption";
import {useAuth} from "../../../auth";
import PopupConfirmWidget from "../../../../../core/widgets/PopupConfirmWidget";

const BranchPage: FC = () => {
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const columnRoleGroups: GridColumns = [
        {field: 'branchID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <>
                    <button className="border-0 rounded bg-transparent"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_branch"
                            onClick={() => {bntPopupBranch(params.value)}}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3' />
                    </button>
                    <button className="border-0 rounded bg-transparent"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_confirm"
                            onClick={() =>{setTxtBranchID(params.value)}}>
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='text-danger svg-icon-3'/>
                    </button>
                </>
            }
        },
        {field: 'parentName', width: 150, editable: true, renderHeader: () => (<strong>Development unit</strong>)},
        {field: 'branchName', width: 150, editable: true, renderHeader: () => (<strong>Branch Name</strong>)},
        {field: 'branchCode', width: 150, editable: true, renderHeader: () => (<strong>Branch Code</strong>)},
        {field: 'displayName', width: 150, editable: true, renderHeader: () => (<strong>Display Name</strong>)},
        {field: 'email', width: 150, editable: true, renderHeader: () => (<strong>Email</strong>)},
        {field: 'phoneNumber', width: 150, editable: true, renderHeader: () => (<strong>PhoneNumber</strong>)},
        {field: 'addressLine01', width: 200, editable: true, renderHeader: () => (<strong>Address</strong>)},
        {field: 'cityCode', width: 150, editable: true, renderHeader: () => (<strong>City Code</strong>)},
        {field: 'countryCode', width: 150, editable: true, renderHeader: () => (<strong>Country Code</strong>)},
        {field: 'isHeadOffice', width: 100, editable: true, renderHeader: () => (<strong>Head Office</strong>)},
        {field: 'isDisable', width: 50, editable: true, renderHeader: () => (<strong>Status</strong>)},
        {field: 'userIDModify', width: 100, editable: true, renderHeader: () => (<strong>User Modify</strong>)},
        {field: 'dateTimeModify', width: 220, editable: true, renderHeader: () => (<strong>Date Modify</strong>)}
    ];

    const [branchData, setBranchData] = useState<Array<BranchData>>();
    const [loadingButtonSearchBranch, setLoadingButtonSearchBranch] = useState(false)
    const [loadingButtonSaveBranch, setLoadingButtonSaveBranch] = useState(false)
    const [txtInputBranch, setInputBranch] = useState('');
    const [branches, setBranches] = useState<Array<BranchData>>(new Array<BranchData>());

    const [txtBranchID, setTxtBranchID] = useState('')
    const [txtBranchParent, setTxtBranchParent] = useState('1');
    const [txtBranchName, setTxtBranchName] = useState('');
    const [txtBranchCode, setTxtBranchCode] = useState('');
    const [txtDisplayName, setTxtDisplayName] = useState('');
    const [txtEmail, setTxtEmail] = useState('');
    const [txtPhoneNumber, setTxtPhoneNumber] = useState('');
    const [txtAddressLine, setTxtAddressLine] = useState('');
    const [txtCityCode, setTxtCityCode] = useState('');
    const [txtCountryCode, setTxtCountryCode] = useState('');
    const [txtIsHeadOffice, setTxtIsHeadOffice] = useState(false);
    
    const [txtValBranchName, setTxtValBranchName] = useState('');
    const [txtValBranchCode, setTxtValBranchCode] = useState('');
    const [txtValDisplayName, setTxtValDisplayName] = useState('');
    const [txtValEmail, setTxtValEmail] = useState('');
    const [txtValPhoneNumber, setTxtValPhoneNumber] = useState('');
    const [txtValAddressLine, setTxtValAddressLine] = useState('');
    const [txtValCityCode, setTxtValCityCode] = useState('');
    const [txtValCountryCode, setTxtValCountryCode] = useState('');
    
    useState(() => {
        GetBranches().then((response) => {
            setBranches(response)
        });
    })

    const validateFormBranch = () => {
        !txtBranchName ? setTxtValBranchName("This field is required") : setTxtValBranchName("")
        !txtBranchCode ? setTxtValBranchCode("This field is required") : setTxtValBranchCode("")
        !txtDisplayName ? setTxtValDisplayName("This field is required") : setTxtValDisplayName("")
        !txtEmail ? setTxtValEmail("This field is required") : setTxtValEmail("")
        !txtPhoneNumber ? setTxtValPhoneNumber("This field is required") : setTxtValPhoneNumber("")
        !txtAddressLine ? setTxtValAddressLine("This field is required") : setTxtValAddressLine("")
        !txtCityCode ? setTxtValCityCode("This field is required") : setTxtValCityCode("")
        !txtCountryCode ? setTxtValCountryCode("This field is required") : setTxtValCountryCode("")

        return !(!txtBranchName || !txtBranchCode || !txtDisplayName || !txtEmail
            || !txtPhoneNumber || !txtAddressLine || !txtCityCode || !txtCountryCode);
    }

    const searchBranch = () => {
        setLoadingButtonSearchBranch(true)
        SearchBranch(txtInputBranch).then((response) => {
            if(response){
                setBranchData(response)
            }else {
                toast.info("Data invalid")
            }
            setLoadingButtonSearchBranch(false)
        });
    }

    const createBranch = () => {
        if(validateFormBranch()){
            setLoadingButtonSaveBranch(true)
            SaveBranch(
                txtBranchID,
                txtBranchParent, 
                txtBranchName, 
                txtBranchCode, 
                txtDisplayName,
                txtEmail,
                txtPhoneNumber,
                txtAddressLine,
                txtCityCode,
                txtCountryCode,
                txtIsHeadOffice,
                false,
                UserID).then((response) => {
                if(response){
                    toast.success(ToastMessageOption.Success);
                    let close = document.getElementById('closeButtonBranch')
                    if(close){
                        close.click()
                    }
                }else {
                    toast.error(ToastMessageOption.Error);
                }
                setLoadingButtonSaveBranch(false)
            });
        }
    }

    const bntPopupBranch = (branchId: string) => {
        if(branchId) {
            QueryBranch(branchId).then((response) => {
                setTxtBranchID(response.branchID.toString())
                setTxtBranchParent(response.parentID.toString())
                setTxtBranchName(response.branchName)
                setTxtBranchCode(response.branchCode)
                setTxtDisplayName(response.displayName)
                setTxtEmail(response.email)
                setTxtPhoneNumber(response.phoneNumber)
                setTxtAddressLine(response.addressLine01)
                setTxtCityCode(response.cityCode)
                setTxtCountryCode(response.countryCode)
                setTxtIsHeadOffice(response.isHeadOffice)
            });
        }else{
            setTxtBranchID('')
            setTxtBranchParent('1')
            setTxtBranchName('')
            setTxtBranchCode('')
            setTxtDisplayName('')
            setTxtEmail('')
            setTxtPhoneNumber('')
            setTxtAddressLine('')
            setTxtCityCode('')
            setTxtCountryCode('')
            setTxtIsHeadOffice(false)
        }
    }
    
    const btnDeleteBranch = () =>{
        SaveBranch(
            txtBranchID,
            txtBranchParent,
            txtBranchName,
            txtBranchCode,
            txtDisplayName,
            txtEmail,
            txtPhoneNumber,
            txtAddressLine,
            txtCityCode,
            txtCountryCode,
            txtIsHeadOffice,
            true,
            UserID).then((response) => {
            if(response){
                toast.success(ToastMessageOption.Success);
            }else {
                toast.error(ToastMessageOption.Error);
            }
        });
    }

    return (
        <>
            {PopupConfirmWidget(() => {btnDeleteBranch()})}
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_branch_data'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Branches</h3>
                    </div>
                </div>
                <div id='kt_branch_data' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        <div className='d-flex flex-wrap align-items-center'>
                            <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                <div className='row mb-1'>
                                    <div className='col-lg-4'>
                                        <label htmlFor="exampleFormControlInput1"
                                               className="form-label">Input branch</label>
                                        <input type="email"
                                               onChange={(e) => {setInputBranch(e.target.value)}}
                                               className="form-control form-control-solid"
                                               placeholder="branch"/>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='mx-auto'>
                                        <button
                                            id='btnReloadSecure'
                                            type='button'
                                            onClick={searchBranch}
                                            className='btn btn-primary me-2'
                                        >
                                            {!loadingButtonSearchBranch && 'Search'}
                                            {loadingButtonSearchBranch && (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <button
                                            id='btnReloadSecure'
                                            type='button'
                                            className='btn btn-success me-2'
                                            data-bs-toggle="modal"
                                            onClick={() => {bntPopupBranch('')}}
                                            data-bs-target="#kt_modal_branch"
                                        >
                                            Create Branch
                                        </button>
                                        {/* Model */}
                                        <div className="modal bg-white fade" tabIndex={-1} id="kt_modal_branch">
                                            <div className="modal-dialog modal-fullscreen">
                                                <div className="modal-content shadow-none">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Create branch</h5>
                                                        <div
                                                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <KTSVG
                                                                path="/media/icons/duotune/arrows/arr061.svg"
                                                                className="svg-icon svg-icon-2x"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <input type="hidden" id="txtRoleId" value={txtBranchID}></input>
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">Branch name</label>
                                                                <input type="text"
                                                                       value={txtBranchName}
                                                                       onChange={(e) => {setTxtBranchName(e.target.value)}}
                                                                       className="form-control form-control-solid"
                                                                       placeholder="Branch name"/>
                                                                <span style={{ color: "red" }}>{txtValBranchName}</span>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">Branch code</label>
                                                                <input type="text"
                                                                       value={txtBranchCode}
                                                                       onChange={(e) => {setTxtBranchCode(e.target.value)}}
                                                                       className="form-control form-control-solid"
                                                                       placeholder="Branch code"/>
                                                                <span style={{ color: "red" }}>{txtValBranchCode}</span>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">Display code</label>
                                                                <input type="text"
                                                                       value={txtDisplayName}
                                                                       onChange={(e) => {setTxtDisplayName(e.target.value)}}
                                                                       className="form-control form-control-solid"
                                                                       placeholder="Display code"/>
                                                                <span style={{ color: "red" }}>{txtValDisplayName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-10">
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">Email</label>
                                                                <input type="text"
                                                                       value={txtEmail}
                                                                       onChange={(e) => {setTxtEmail(e.target.value)}}
                                                                       className="form-control form-control-solid"
                                                                       placeholder="Email"/>
                                                                <span style={{ color: "red" }}>{txtValEmail}</span>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">PhoneNumber</label>
                                                                <input type="text"
                                                                       value={txtPhoneNumber}
                                                                       onChange={(e) => {setTxtPhoneNumber(e.target.value)}}
                                                                       className="form-control form-control-solid"
                                                                       placeholder="PhoneNumber"/>
                                                                <span style={{ color: "red" }}>{txtValPhoneNumber}</span>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="form-label">Development unit</label>
                                                                <select className="form-select form-select-solid"
                                                                        value={txtBranchParent}
                                                                        onChange={(e) => {setTxtBranchParent(e.target.value)}}
                                                                        aria-label="Select example">
                                                                    {
                                                                        branches.map((branch) => (
                                                                            <option key={branch.branchID} value={branch.branchID}>{branch.branchCode} - {branch.branchName}</option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="row mt-10">
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">Country code</label>
                                                                <input type="text"
                                                                        value={txtCountryCode}
                                                                       onChange={(e) => {setTxtCountryCode(e.target.value)}}
                                                                       className="form-control form-control-solid"
                                                                       placeholder="Country Code"/>
                                                                <span style={{ color: "red" }}>{txtValCountryCode}</span>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">City code</label>
                                                                <input type="text"
                                                                       value={txtCityCode}
                                                                       onChange={(e) => {setTxtCityCode(e.target.value)}}
                                                                       className="form-control form-control-solid"
                                                                       placeholder="City code"/>
                                                                <span style={{ color: "red" }}>{txtValCityCode}</span>
                                                            </div>
                                                            <div className='col-lg-4'>
                                                                <label htmlFor="exampleFormControlInput1"
                                                                       className="required form-label">Address</label>
                                                                <textarea className="form-control form-control-solid"
                                                                          value={txtAddressLine}
                                                                          onInput={(e) => setTxtAddressLine((e.target as HTMLInputElement).value)}
                                                                          placeholder="address"/>
                                                                <span style={{ color: "red" }}>{txtValAddressLine}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-lg-8"></div>
                                                            <div className="col-lg-4">
                                                                <div className="form-check form-check-custom form-check-solid">
                                                                    <input className="form-check-input h-30px w-30px"
                                                                           type="checkbox" value=""
                                                                           id="flexCheckDefault"
                                                                           onClick={() => setTxtIsHeadOffice(!txtIsHeadOffice)}
                                                                           checked={txtIsHeadOffice}/>
                                                                    <label className="form-check-label"
                                                                           htmlFor="flexCheckDefault">
                                                                        <b>Head office</b>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            id="closeButtonBranch"
                                                            type="button"
                                                            className="btn btn-light"
                                                            data-bs-dismiss="modal"
                                                        >
                                                            Close
                                                        </button>
                                                        <button type="button" onClick={createBranch} className="btn btn-primary">
                                                            {!loadingButtonSaveBranch && 'Save'}
                                                            {loadingButtonSaveBranch && (
                                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {branchData && (
                                    <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                        <StripedDataGrid
                                            loading={false}
                                            rows={branchData}
                                            columns={columnRoleGroups}
                                            getRowClassName={(params) =>
                                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                            }
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            components={{
                                                Pagination: CustomPagination,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {BranchPage}
