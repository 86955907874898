export enum UserStatusOption {
    New = "NEW",
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    Blocked = "BLOCKED",
    Deleted = "DELETED"
}

export enum ContactStatusOption {
    New = "NEW",
    Process = "PROCESS",
    Finish = "FINISH"
}

export enum PaymentStatusOption {
    Init = "INIT",
    Paid = "PAID"
}