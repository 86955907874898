import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {BranchPage} from "./components/BranchPage";
import {PageTitle} from "../../../../_metronic/layout/core";
import {BranchPermissionPage} from "./components/BranchPermissionPage";
import { useAuth } from '../../auth';
import { useEffect, useState } from 'react';
import { IsEmpty } from '../../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';


export default function BranchRouter() {
    const {currentUser} = useAuth();
    const [branchRole, setBranchRole] = useState(false)

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setBranchRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'ADMIN_BRANCH')));
        }
    }, [])
    
    return (
        <>
            { branchRole ? (
                <Routes>
                    <Route element={<Outlet />}>
                        <Route 
                            path='information' 
                            element={
                            <>
                                <PageTitle breadcrumbs={[]}>Branch Information</PageTitle>
                                <BranchPage/>
                            </>
                        } />
                        <Route
                            path='permission'
                            element={
                            <>
                                <PageTitle breadcrumbs={[]}>Branch Permission</PageTitle>
                                <BranchPermissionPage/>
                            </>
                        } />
                    </Route>
                    <Route index element={<Navigate to='/information' />} />
                </Routes>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}
        </>    
    )
}