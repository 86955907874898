import React, {FC, useEffect, useRef, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {BranchData} from "../core/models";
import {GetBranches, SaveBranchPermission, SearchBranchPermission} from "../core/service";
import {GetRoleGroup} from "../../roles/core/service";
import {RoleGroupData} from "../../roles/core/models";
import {ToastMessageOption} from "../../../../../core/options/toastMessageOption";
import {useAuth} from "../../../auth";
import { LoadingPage } from '../../../../../core/components/LoadingPage';
import { LoadingPageData } from '../../../../../core/models/baseData';


const BranchPermissionPage: FC = () => {
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const childRef = useRef<LoadingPageData>(null)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<Array<string>>([]);
    const [loadingButtonSaveBranchPermission, setLoadingButtonSaveBranchPermission] = useState(false)
    const [txtInputBranch, setTxtBranch] = useState('');
    const [branches, setBranches] = useState<Array<BranchData>>(new Array<BranchData>());
    const [roleGroups, setRoleGroups] = useState<Array<RoleGroupData>>(new Array<RoleGroupData>());

    useEffect(() => {
        childRef.current?.handleToggle();
        GetBranches().then((response) => {
            setBranches(response)
        });

        GetRoleGroup().then((response) => {
            setRoleGroups(response)
            childRef.current?.handleClose();
        });
    },[])

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(roleGroups.map(roleGroup => roleGroup.roleGroupID.toString()));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleSelect = (e: any) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const searchBranchPermission = (txtInput: string) => {
        setIsCheckAll(false)
        setTxtBranch(txtInput)
        SearchBranchPermission(txtInput).then((response) => {
            if(response){
                let roleGroups: Array<string> = response.map(({ roleGroupID }) => roleGroupID.toString());
                setIsCheck(roleGroups)
            }else {
                toast.info("Data invalid")
            }
        });
    }

    const saveBranchPermission = () => {
        setLoadingButtonSaveBranchPermission(true)
        SaveBranchPermission(txtInputBranch, isCheck, UserID).then((response) => {
            if(response){
                toast.success(ToastMessageOption.Success);
            }else {
                toast.info("Data invalid")
            }
            setLoadingButtonSaveBranchPermission(false)
        });
    }
    
    return (
        <>
            <LoadingPage ref={childRef} />
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0 cursor-pointer'>
                   
                </div>
                <div id='kt_branch_data' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        <div className='d-flex flex-wrap align-items-center'>
                            <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                <div className='row mb-1'>
                                    <div className='col-lg-4'></div>
                                    <div className='col-lg-4 text-center'>
                                        <select className="form-select form-select-solid"
                                                onChange={(e) => {searchBranchPermission(e.target.value)}}
                                                aria-label="Select example">
                                            <option value="">Select role group</option>
                                            {branches.map((branch) => (
                                                <option key={branch.branchID} value={branch.branchID}>{branch.branchCode} - {branch.branchName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {txtInputBranch &&
                                    <div className='card mt-5 mb-xl-8'>
                                        <div className='card-body py-3'>
                                            <div className='table-responsive'>
                                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                                    <thead>
                                                    <tr className='fw-bolder fw-bolder'>
                                                        <th className='w-25px'>
                                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                                <input
                                                                    className='form-check-input h-25px w-25px'
                                                                    type='checkbox'
                                                                    value='1'
                                                                    data-kt-check='true'
                                                                    data-kt-check-target='.widget-9-check'
                                                                    checked={isCheckAll}
                                                                    onChange={handleSelectAll}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th className='min-w-150px'><b>Role Group Name</b></th>
                                                        <th className='min-w-140px'>Description</th>
                                                        <th className='min-w-100px text-end'>DateTime Create</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {roleGroups.map((roleGroup) => (
                                                        <tr key={roleGroup.roleGroupID}>
                                                            <td>
                                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                                    <input
                                                                        id={roleGroup.roleGroupID.toString()}
                                                                        className='form-check-input widget-9-check h-25px w-25px'
                                                                        type='checkbox'
                                                                        value={roleGroup.roleGroupID.toString()}
                                                                        checked={isCheck.includes(roleGroup.roleGroupID.toString())}
                                                                        onChange={handleSelect}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="fs-5">
                                                                {roleGroup.roleGroupName}
                                                            </td>
                                                            <td>
                                                                {roleGroup.description}
                                                            </td>
                                                            <td className="text-end">
                                                                {roleGroup.dateTimeModify}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {txtInputBranch &&
                                    <div className='d-flex'>
                                        <div className='mx-auto'>
                                            <button
                                                id='btnReloadSecure'
                                                type='button'
                                                className='btn btn-success me-2'
                                                onClick={saveBranchPermission}
                                            >
                                                {!loadingButtonSaveBranchPermission && 'Save Branch Permission'}
                                                {loadingButtonSaveBranchPermission && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {BranchPermissionPage}