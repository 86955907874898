/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../app/admin/auth'
import { IsEmpty } from '../../../../core/utilities/functionBase'

export function AsideMenuMain() {
    const intl = useIntl()
    const {currentUser} = useAuth()

    const [isUser, setUser] = useState(false)
    const [isBranch, setBranch] = useState(false)
    const [isRole, setRole] = useState(false)
    const [isSupport, setSupport] = useState(false)
    const [isBooking, setBooking] = useState(false)

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setUser(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'ADMIN_USER')));
            setBranch(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'ADMIN_BRANCH')));
            setRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'ADMIN_ROLE')));
            setSupport(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_SUPPORTS')));
            setBooking(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_BOOKING')));
        }
    }, [])

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />

            {/* ============== Developments ============== */}
            {/* USER MANAGEMENTS */} 
            { (isUser || isBranch || isRole) && 
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administration</span>
                    </div>
                </div>
            }
            
            { isUser && 
                <AsideMenuItemWithSub
                    to='/admin/user'
                    title='User managements'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen022.svg'
                >
                    <AsideMenuItem to='/admin/user-management/users' title='User Search' hasBullet={true} />
                </AsideMenuItemWithSub>
            }

            { isBranch &&
                <AsideMenuItemWithSub
                    to='/admin/branches'
                    title='Branches'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gen022.svg'
                >
                    <AsideMenuItem to='/admin/branch/information' title='Branch Information' hasBullet={true} />
                    <AsideMenuItem to='/admin/branch/permission' title='Branch Permission' hasBullet={true} />
                </AsideMenuItemWithSub>
            }
            
            { isRole &&
                <AsideMenuItem
                    to='/admin/role'
                    icon='/media/icons/duotune/general/gen019.svg'
                    title='Role'
                    fontIcon='bi-layers'
                />
            }

            { isBooking && 
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Book</span>
                        </div>
                    </div>
                    <AsideMenuItem
                        to='/book/room'
                        icon='/media/icons/duotune/general/gen022.svg'
                        title='Room'
                        fontIcon='bi-layers'
                    />
                    <AsideMenuItem
                        to='/book/service'
                        icon='/media/icons/duotune/general/gen022.svg'
                        title='Service'
                        fontIcon='bi-layers'
                    />
                    <AsideMenuItem
                        to='/book/order-search'
                        icon='/media/icons/duotune/general/gen022.svg'
                        title='Booking'
                        fontIcon='bi-layers'
                    />
                </>
            }
           
            { isSupport &&
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Supports</span>
                        </div>
                    </div>
                    <AsideMenuItem
                        to='/support/contact'
                        icon='/media/icons/duotune/general/gen022.svg'
                        title='Contact'
                        fontIcon='bi-layers'
                    />
                    <AsideMenuItem
                        to='/support/tourist-attraction'
                        icon='/media/icons/duotune/general/gen022.svg'
                        title='Tourist attraction'
                        fontIcon='bi-layers'
                    />
                    <AsideMenuItem
                        to='/support/tourist-places'
                        icon='/media/icons/duotune/general/gen022.svg'
                        title='Tourist places'
                        fontIcon='bi-layers'
                    />
                </>
            }

            
            {/* ============== Version ============== */}
            <div className='menu-item'>
                <div className='menu-content'>
                    <div className='separator mx-1 my-4'></div>
                </div>
            </div>
            <div className='menu-item'>
                <a target='_blank' className='menu-link' href="">
                    <span className='menu-icon'>
                        <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
                    </span>
                    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
                </a>
            </div>
        </>
    )
}
