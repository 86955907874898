export default function PopupConfirmWidget(funAction: () => void, idModel = "kt_modal_confirm") {
    const btnConfirm = () =>{
        funAction()
        let close = document.getElementById(`btn-`+ idModel)
        if(close){
            close.click()
        }
    }
    
    return (
        <div className="modal fade" tabIndex={-1} id={idModel}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <img src="https://cdn-icons-png.flaticon.com/512/10275/10275279.png" height="130px"/>
                        <h5 className="mt-5">Are you sure to delete this?</h5>
                    </div>
                    <div className="modal-footer">
                        <button
                            id={`btn-`+ idModel}
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button type="button" 
                                className="btn btn-danger"
                                onClick={() => {btnConfirm()}}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}