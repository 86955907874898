import React, {FC, useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {useAuth} from "../auth";
import {GridColumns} from "@mui/x-data-grid";
import {KTSVG} from "../../../_metronic/helpers";
import {CityModel, TouristAttractionData} from "./core/models";
import StripedDataGrid from '../../../core/components/data-grid/StripedDataGridPage';
import CustomPagination from "../../../core/components/data-grid/PaginationPage";
import {
    createTouristAttraction,
    getCity,
    queryTouristAttraction,
    searchTouristAttraction,
    updateTouristAttraction
} from "./core/service";
import {ToastMessageOption} from "../../../core/options/toastMessageOption";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import { IsEmpty } from '../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';

const months = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
]


const TouristAttractionPage: FC = () => {
    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Tourist Attraction',
            path: '/support/tourist-attraction',
            isSeparator: false,
            isActive: false,
        }
    ]
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const columnRoleGroups: GridColumns = [
        {field: 'touristAttractionID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <button className="border-0 rounded bg-transparent"
                               data-bs-toggle="modal"
                               data-bs-target="#kt_modal_tourist-attraction"
                               onClick={() =>{query(params.value)}}>
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3'/>
                </button>
            }
        },
        {field: 'touristAttractionName', width: 150, editable: true, renderHeader: () => (<strong>Name</strong>)},
        {field: 'content', width: 300, editable: true, renderHeader: () => (<strong>Content</strong>)},
        {field: 'cityCode', width: 150, editable: true, renderHeader: () => (<strong>City Code</strong>)},
        {field: 'month', width: 150, editable: true, renderHeader: () => (<strong>Month</strong>)},
        {field: 'dateTimeCreate', width: 220, editable: true, renderHeader: () => (<strong>Create Date time</strong>)}
    ];
    const [cityData, setCityData] = React.useState<Array<CityModel>>();
    const [touristAttractionData, setTouristAttractionData] = React.useState<Array<TouristAttractionData>>();
    const [loadingButtonSearch, setLoadingButtonSearch] = useState(false)
    const [loadingButtonSaveRole, setLoadingButtonSaveRole] = useState(false)

    const [txtTouristAttraction, setTxtTouristAttraction] = useState('');
    const [txtCity, setTxtCity] = useState('');
    const [txtMonth, setTxtMonth] = useState('');
    
    const [txtTouristAttractionValue, setTouristAttractionValue] = useState('');
    const [txtCityValue, setCityValue] = useState('054');
    const [txtMonthValue, setMonthValue] = useState('1');
    const [txtImageUrl, setImageUrl] = useState('');
    const [txtContentValue, setContentValue] = useState('');
  
    const [isUpdate, setIsUpdate] = useState(false)
    const [touristAttraction, setTouristAttraction] = React.useState<TouristAttractionData>();
    const [supportRole, setSupportRole] = React.useState(false);

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setSupportRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_SUPPORTS')));
        }
        
        getCity().then((response) => {
            setCityData(response)
        })
    },[])

    const search = () => {
        setLoadingButtonSearch(true)
        searchTouristAttraction(
            txtTouristAttraction,
            txtCity,
            Number(txtMonth)
        ).then((response) => {
            setTouristAttractionData(response)
            setLoadingButtonSearch(false)
        });
    }

    const query = (touristAttraction: number) => {
        setIsUpdate(true)
        queryTouristAttraction(
            touristAttraction
        ).then((response) => {
            if(response){
                setTouristAttractionValue(response.touristAttractionName)
                setCityValue(response.cityCode)
                setMonthValue(response.month.toString())
                setImageUrl(response.imageUrl)
                setContentValue(response.content)
                setTouristAttraction(response)
            }
        });
    }
    
    const create = () => {
        setIsUpdate(false)
        setTouristAttractionValue('')
        setCityValue('054')
        setMonthValue('1')
        setImageUrl('')
        setContentValue('')
    }

    const update = () =>{
        setLoadingButtonSaveRole(true)
        if(isUpdate && touristAttraction){
            updateTouristAttraction(
                touristAttraction.touristAttractionID,
                txtTouristAttractionValue,
                txtCityValue,
                txtImageUrl, 
                txtContentValue,
                Number(txtMonthValue),
                UserID || 0
            ).then((response) => {
                response ? toast.success(ToastMessageOption.Success)
                         : toast.error(ToastMessageOption.Error)
                setLoadingButtonSaveRole(false)
                search()
            });
        } else {
            createTouristAttraction(
                txtTouristAttractionValue,
                txtCityValue,
                txtImageUrl,
                txtContentValue,
                Number(txtMonthValue),
                UserID || 0
            ).then((response) => {
                response ? toast.success(ToastMessageOption.Success)
                         : toast.error(ToastMessageOption.Error)
                setLoadingButtonSaveRole(false)
                search()
            });
        }
    }

    return (
        <>
            { supportRole ? (
                <>
                    <PageTitle breadcrumbs={usersBreadcrumbs}></PageTitle>
                    <div className='card mb-5 mb-xl-10'>
                        <div id='kt_branch_data' className='collapse show'>
                            <div className='card-body border-top p-9'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                        <div className='row mb-1'>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Tourist attraction</label>
                                                <input type="text"
                                                    onChange={(e) => {
                                                        setTxtTouristAttraction(e.target.value)
                                                    }}
                                                    className="form-control form-control-solid"
                                                    placeholder="Tourist attraction"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblCity'
                                                        className='form-label fs-6'>City</label>
                                                    <select className='form-select form-select-solid'
                                                            onChange={(e) => {
                                                                setTxtCity(e.target.value)}
                                                            }>
                                                        <option value='' >Select all</option>
                                                        {cityData?.map((item) =>
                                                            <option key={item.cityID} value={item.cityCode} >
                                                                {item.cityCode} - {item.cityName}
                                                            </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblBranchCode'
                                                        className='form-label fs-6'>Months</label>
                                                    <select className='form-select form-select-solid'
                                                            onChange={(e) => {
                                                                setTxtMonth(e.target.value)}
                                                            }>
                                                        <option value='' >Select all</option>
                                                        {months?.map((item) =>
                                                            <option key={item.value} value={item.value} >{item.value} - {item.label}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-5'>
                                            <div className='mx-auto'>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    onClick={search}
                                                    className='btn btn-primary me-2'
                                                >
                                                    {!loadingButtonSearch && 'Search'}
                                                    {loadingButtonSearch && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    className='btn btn-success me-2'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_tourist-attraction"
                                                    onClick={() => create()}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                        {touristAttractionData && (
                                            <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                                <StripedDataGrid
                                                    loading={false}
                                                    rows={touristAttractionData}
                                                    columns={columnRoleGroups}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                    }
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    components={{
                                                        Pagination: CustomPagination,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" tabIndex={-1} id="kt_modal_tourist-attraction">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Tourist attraction view</h5>
                                    <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr061.svg"
                                            className="svg-icon svg-icon-2x"
                                        />
                                    </div>
                                </div>
                                    <div className="modal-body">
                                        <div>
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Tourist attraction name</label>
                                            <input type="text"
                                                className="form-control form-control-solid"
                                                value={txtTouristAttractionValue}
                                                onChange={(e) => {
                                                    setTouristAttractionValue(e.target.value)}
                                                }/>
                                        </div>
                                        <div>
                                            <label htmlFor='lblCity'
                                                className='form-label fs-6'>City</label>
                                            <select className='form-select form-select-solid'
                                                    onChange={(e) => {
                                                        setCityValue(e.target.value)}
                                                    }
                                                    value={txtCityValue}
                                                    >
                                                {cityData?.map((item) =>
                                                    <option key={item.cityID} value={item.cityCode} >
                                                        {item.cityCode} - {item.cityName}
                                                    </option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="mt-5">
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Content</label>
                                            <textarea className="form-control form-control-solid"
                                                    value={txtContentValue}
                                                    onChange={(e) => {
                                                        setContentValue(e.target.value)}
                                                    }/>
                                        </div>
                                        <div className="mt-5">
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Image url</label>
                                            <textarea className="form-control form-control-solid"
                                                    value={txtImageUrl}
                                                    onChange={(e) => {
                                                        setImageUrl(e.target.value)}
                                                    }/>
                                        </div>
                                        <div className="mt-5">
                                            <label htmlFor='lblBranchCode'
                                                className='form-label fs-6'>Month</label>
                                            <select className='form-select form-select-solid'
                                                    onChange={(e) => {
                                                        setMonthValue(e.target.value)}
                                                    }
                                                    value={txtMonthValue}>
                                                {months?.map((item) =>
                                                    <option key={item.value} value={item.value} >{item.value} - {item.label}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                
                                <div className="modal-footer">
                                    <button
                                        id="closeButtonRole"
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal">
                                        Close
                                    </button>
                                    <button type="button"
                                            onClick={update}
                                            className="btn btn-primary">
                                        {!loadingButtonSaveRole && 'Save'}
                                        {loadingButtonSaveRole && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                                            Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                        </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}
        </>
    )
}

export {TouristAttractionPage}
