export enum PatternOption {
    Date = "yyyyMMdd",
    DateDisplay = "dd/MM/yyyy",
    DateTime = "yyyyMMddHHmmss",
    DateUniversal = "yyyy-MM-dd",
    DateTimeDisplay = "dd/MM/yyyy HH:mm:ss",
    DateTimeUniversal = "yyyy-MM-dd'T'HH:mm:ss'Z'",
    DateTimeFullUniversal = "yyyy-MM-ddTHH:mm:ss.ffffff",

    MonthDate = "MMdd",
    YearMonth = "yyyyMM",
    ShortDate = "yyMMdd",
    ShortMonthYear = "MM/yy"
}