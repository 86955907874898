import React, {FC, useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../auth";
import {GridColumns} from "@mui/x-data-grid";
import {KTSVG} from "../../../../_metronic/helpers";
import {RoomData} from "./core/models";
import StripedDataGrid from '../../../../core/components/data-grid/StripedDataGridPage';
import CustomPagination from "../../../../core/components/data-grid/PaginationPage";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import { Link, useNavigate } from 'react-router-dom';
import { deleteRoom, searchRoom } from './core/service';
import PopupConfirmWidget from '../../../../core/widgets/PopupConfirmWidget';
import { toast } from 'react-toastify';
import { ToastMessageOption } from '../../../../core/options/toastMessageOption';
import { IsEmpty } from '../../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';

const listGuest = [
    { label: '1 bed', value: 1 },
    { label: '2 bed', value: 2 },
    { label: '3 bed', value: 3 }
]

const RoomPage: FC = () => {
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const subBranch = currentUser?.subBranch

    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Room',
            path: '/book/room',
            isSeparator: false,
            isActive: false,
        }
    ]
    const navigate = useNavigate();
    const columnRoleGroups: GridColumns = [
        {field: 'roomID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <>
                    <Link to={`/book/room-inquiry/${params.value}`}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3'/>
                    </Link>
                    <button className="border-0 rounded bg-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_delete_room"
                        onClick={() =>{setTxtRoomID(params.value)}}>
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='text-danger svg-icon-3'/>
                    </button>
                </>
            }
        },
        {field: 'roomName', width: 150, editable: true, renderHeader: () => (<strong>Name</strong>)},
        {field: 'bedNumber', width: 100, editable: true, renderHeader: () => (<strong>Bed Number</strong>)},
        {field: 'people', width: 100, editable: true, renderHeader: () => (<strong>People</strong>)},
        {field: 'price', width: 150, editable: true, renderHeader: () => (<strong>Price</strong>)},
        {field: 'cityCode', width: 100, editable: true, renderHeader: () => (<strong>City Code</strong>)},
        {field: 'phoneNumber', width: 200, editable: true, renderHeader: () => (<strong>Phone Number</strong>)},
        {field: 'countSearch', width: 100, editable: true, renderHeader: () => (<strong>Total search</strong>)},
        {field: 'countBook', width: 100, editable: true, renderHeader: () => (<strong>Total booking</strong>)},
        {field: 'star', width: 100, editable: true, renderHeader: () => (<strong>Star</strong>)},
        {field: 'dateTimeModify', width: 200, editable: true, renderHeader: () => (<strong>Date time modify</strong>)}
    ];
    const [roomData, setRoomData] = React.useState<Array<RoomData>>();
    const [txtRoomID, setTxtRoomID] = useState(0)
    const [loadingButtonSearch, setLoadingButtonSearch] = useState(false)
    const [txtRoomName, setTxtRoomName] = useState('');
    const [txtBedNumber, setTxtBedNumber] = useState('');
    const [bookingRole, setBookingRole] = React.useState(false);

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setBookingRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_BOOKING')));
        }
    },[])
    
    const search = () => {
        if(subBranch){
            setLoadingButtonSearch(true)
            searchRoom(
                txtRoomName,
                Number(txtBedNumber),
                subBranch.map(item => item.branchID)
            ).then((response) => {
                setRoomData(response)
                setLoadingButtonSearch(false)
            });
        }
    }

    const create = () => {
        navigate("/book/room-inquiry/0");
    }

    const btnDelete = () =>{
        deleteRoom(txtRoomID, UserID || 0).then((response) => {
            if(response){
                toast.success(ToastMessageOption.Success);
            } else {
                toast.error(ToastMessageOption.Error);
            }
        });
    }

    return (
        <>
            { bookingRole ? (
                <>
                    {PopupConfirmWidget(() => {btnDelete()}, "kt_modal_delete_room")}
                    <PageTitle breadcrumbs={usersBreadcrumbs}></PageTitle>
                    <div className='card mb-5 mb-xl-10'>
                        <div id='kt_branch_data' className='collapse show'>
                            <div className='card-body border-top p-9'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                        <div className='row mb-1'>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Room name</label>
                                                <input type="text"
                                                    onChange={(e) => {
                                                            setTxtRoomName(e.target.value)
                                                    }}
                                                    className="form-control form-control-solid"
                                                    placeholder="Room name"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblBedNumber'
                                                        className='form-label fs-6'>Bed number</label>
                                                    <select className='form-select form-select-solid'
                                                            onChange={(e) => {
                                                                setTxtBedNumber(e.target.value)}
                                                            }>
                                                        <option value='' >Select all</option>
                                                        {listGuest?.map((item) =>
                                                            <option key={item.value} value={item.value} >
                                                                {item.label}
                                                            </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-5'>
                                            <div className='mx-auto'>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    onClick={search}
                                                    className='btn btn-primary me-2'
                                                >
                                                    {!loadingButtonSearch && 'Search'}
                                                    {loadingButtonSearch && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    className='btn btn-success me-2'
                                                    onClick={() => create()}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                        {roomData && (
                                            <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                                <StripedDataGrid
                                                    loading={false}
                                                    rows={roomData}
                                                    columns={columnRoleGroups}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                    }
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    components={{
                                                        Pagination: CustomPagination,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}
        </>
    )
}

export {RoomPage}
