import { Route, Routes } from 'react-router-dom';
import { PageTitle } from "../../../../_metronic/layout/core";
import {RolePage} from "./components/RolePage";
import {RoleGroupPage} from "./components/RoleGroupPage";
import { useAuth } from '../../auth';
import { useEffect, useState } from 'react';
import { IsEmpty } from '../../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';

export default function RoleRouter() {
    const {currentUser} = useAuth();
    const [role, setRole] = useState(false)

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'ADMIN_ROLE')));
        }
    }, [])

    return (
        <>
            { role ? (
                <Routes>
                    <Route index element={
                        <>
                            <PageTitle breadcrumbs={[]}>Role - Role Group</PageTitle>
                            <RolePage/>
                            <RoleGroupPage/>
                        </>
                    } />
                </Routes>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}
        </>
        
    )
}
