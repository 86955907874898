import {v4 as uuidv4} from 'uuid';
import { PatternOption } from '../options/patternOption';

export function IsEmpty(data: object | undefined){
    return !data || Object.keys(data).length <= 0;
}

export function GetGuid() {
    return uuidv4().replaceAll("-", "");
}

export function GetDateTime(format: string) {
    let result;
    let now = new Date(); // gen datetime UTC

    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1);
    if (month.length === 1) {
        month = "0" + month;
    }
    let day = "" + now.getDate();
    if (day.length === 1) {
        day = "0" + day;
    }
    let hour = "" + now.getHours();
    if (hour.length === 1) {
        hour = "0" + hour;
    }
    let minute = "" + now.getMinutes();
    if (minute.length === 1) {
        minute = "0" + minute;
    }
    let second = "" + now.getSeconds();
    if (second.length === 1) {
        second = "0" + second;
    }

    switch(format){
        case PatternOption.Date:
            result = `${year}${month}${day}`;
            break;
        case PatternOption.DateTimeDisplay:
            result = `${year}/${month}/${day} ${day}:${minute}:${second}`;
            break;
        default:
            result = `${year}${month}${day}${hour}${minute}${second}`;
    }

    return result;
}

export function FormatDateTimeToLong(dateTime: Date) {
    let year = "" + dateTime.getFullYear();
    let month = "" + (dateTime.getMonth() + 1);
    if (month.length === 1) {
        month = "0" + month;
    }
    let day = "" + dateTime.getDate();
    if (day.length === 1) {
        day = "0" + day;
    }
    let hour = "" + dateTime.getHours();
    if (hour.length === 1) {
        hour = "0" + hour;
    }
    let minute = "" + dateTime.getMinutes();
    if (minute.length === 1) {
        minute = "0" + minute;
    }
    let second = "" + dateTime.getSeconds();
    if (second.length === 1) {
        second = "0" + second;
    }

    return Number(`${year}${month}${day}${hour}${minute}${second}`);
}

export function DateTimeToISOstring(date: number) {
    let dateTime = new Date();
    dateTime.setDate(dateTime.getDate() - date);
    
    let year = "" + dateTime.getFullYear();
    let month = "" + (dateTime.getMonth() + 1);
    if (month.length === 1) {
        month = "0" + month;
    }
    let day = "" + dateTime.getDate();
    if (day.length === 1) {
        day = "0" + day;
    }
    let hour = "" + dateTime.getHours();
    if (hour.length === 1) {
        hour = "0" + hour;
    }
    let minute = "" + dateTime.getMinutes();
    if (minute.length === 1) {
        minute = "0" + minute;
    }
    let second = "" + dateTime.getSeconds();
    if (second.length === 1) {
        second = "0" + second;
    }

    return `${year}-${month}-${day}T${hour}:${minute}`;
}