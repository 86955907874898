import React, {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    MixedWidget10,
    MixedWidget11
} from '../../../_metronic/partials/widgets'
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG'
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid'
import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useAuth } from '../../admin/auth'
import { getCalendarData, getReportData } from './core/service'
import { CalendarData, ReportData } from './core/model'
import { LoadingPage } from '../../../core/components/LoadingPage'
import { LoadingPageData } from '../../../core/models/baseData'
import { IsEmpty } from '../../../core/utilities/functionBase'

const columns: GridColDef[] = [
  { field: 'roomName', width: 150, cellClassName: 'bold', renderHeader: () => (<strong>Room | Date</strong>) },
  { field: '_01', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>01</strong>) },
  { field: '_02', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>02</strong>) },
  { field: '_03', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>03</strong>) },
  { field: '_04', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>04</strong>) },
  { field: '_05', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>05</strong>) },
  { field: '_06', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>06</strong>) },
  { field: '_07', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>07</strong>) },
  { field: '_08', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>08</strong>) },
  { field: '_09', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>09</strong>) },
  { field: '_10', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>10</strong>) },
  { field: '_11', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>11</strong>) },
  { field: '_12', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>12</strong>) },
  { field: '_13', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>13</strong>) },
  { field: '_14', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>14</strong>) },
  { field: '_15', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>15</strong>) },
  { field: '_16', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>16</strong>) },
  { field: '_17', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>17</strong>) },
  { field: '_18', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>18</strong>) },
  { field: '_19', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>19</strong>) },
  { field: '_20', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>20</strong>) },
  { field: '_21', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>21</strong>) },
  { field: '_22', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>22</strong>) },
  { field: '_23', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>23</strong>) },
  { field: '_24', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>24</strong>) },
  { field: '_25', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>25</strong>) },
  { field: '_26', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>26</strong>) },
  { field: '_27', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>27</strong>) },
  { field: '_28', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>28</strong>) },
  { field: '_29', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>29</strong>) },
  { field: '_30', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>30</strong>) },
  { field: '_31', width: 70, type: 'number', valueFormatter: ({ value }) => `${value}`, renderHeader: () => (<strong>31</strong>) }
];

const DashboardPage: FC = () => {
    const {currentUser} = useAuth()
    const childRef = useRef<LoadingPageData>(null)
    const subBranch = currentUser?.subBranch
    const [selectBranch, setSelectBranch] = React.useState('1');
    const [lastMonth, setLastMonth] = React.useState('');
    const [thisMonth, setThisMonth] = React.useState('');
    const [nextMonth, setNextMonth] = React.useState('');
    const [indexMonth, setIndexMonth] = React.useState(0);
    const [calendarData, setCalendarData] = useState<Array<CalendarData>>();
    const [reportData, setReportData] = useState<ReportData>();
    const [reportRole, setReportRole] = React.useState(false);

    useEffect(() => {
        childRef.current?.handleToggle()

        if(currentUser && currentUser.userRoles){
            setReportRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'ADMIN_REPORT')));
        }

        if (subBranch && subBranch.length > 0) {
            setSelectBranch(subBranch[0].branchID.toString())
            getReportData(subBranch.map(item => item.branchID)).then((response) => {
                setReportData(response)
            })

            const thisDate = new Date();
            let thisYear = thisDate.getFullYear();
            let thismMonth = '' + (thisDate.getMonth() + 1);
            if (thismMonth.length === 1) {
                thismMonth = "0" + thismMonth;
            }
            setThisMonth(`${thisYear}${thismMonth}`);

            const lastDate = new Date();
            lastDate.setMonth(lastDate.getMonth() - 1);
            let lastYear = lastDate.getFullYear();
            let lastMonth = '' + (lastDate.getMonth() + 1);
            if (lastMonth.length === 1) {
                lastMonth = "0" + lastMonth;
            }
            setLastMonth(`${lastYear}${lastMonth}`);

            const nextDate = new Date();
            nextDate.setMonth(nextDate.getMonth() + 1);
            let nextYear = nextDate.getFullYear();
            let nextMonth = '' + (nextDate.getMonth() + 1);
            if (nextMonth.length === 1) {
                nextMonth = "0" + nextMonth;
            }
            setNextMonth(`${nextYear}${nextMonth}`);

            getCalendar(selectBranch, `${thisYear}${thismMonth}`)
        } else {
            childRef.current?.handleClose();
        }
    },[])

    const getCalendar = (branch: string, month: string) => {
        getCalendarData(Number(branch), month).then((response) => {
            setCalendarData(response)
            childRef.current?.handleClose()
        })
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSelectBranch(event.target.value);
        getCalendar(event.target.value, thisMonth);
    };

    return (
        <>
            <LoadingPage ref={childRef} />
            {reportData && 
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xxl-3'>
                    <div className='d-flex align-items-center bg-light-info rounded p-5'>
                        <span className='svg-icon svg-icon-info me-5'>
                            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                        </span>
                        <div className='flex-grow-1 me-2'>
                            <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                Total users
                            </a>
                            <span className='text-muted fw-bold d-block'>Present</span>
                        </div>
                        <span className='fw-bolder text-info py-1'>{reportData.totalUser} User</span>
                    </div>
                </div>
                <div className='col-xxl-3'>
                    <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
                        <span className='svg-icon svg-icon-danger me-5'>
                            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                        </span>
                        <div className='flex-grow-1 me-2'>
                            <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                Total room
                            </a>
                            <span className='text-muted fw-bold d-block'>Present</span>
                        </div>
                        <span className='fw-bolder text-danger py-1'>{reportData.totalRoom} Room</span>
                    </div>
                </div>
                <div className='col-xxl-3'>
                    <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
                        <span className='svg-icon svg-icon-success me-5'>
                            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                        </span>
                        <div className='flex-grow-1 me-2'>
                            <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                Total service
                            </a>
                            <span className='text-muted fw-bold d-block'>Present</span>
                        </div>
                        <span className='fw-bolder text-success py-1'>{reportData.totalService} Serice</span>
                    </div>
                </div>
                <div className='col-xxl-3'>
                    <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
                        <span className='svg-icon svg-icon-warning me-5'>
                            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                        </span>
                        <div className='flex-grow-1 me-2'>
                            <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                Total booking
                            </a>
                            <span className='text-muted fw-bold d-block'>In this month</span>
                        </div>
                        <span className='fw-bolder text-warning py-1'>{reportData.totalOrder}</span>
                    </div>
                </div>
            </div>
        }

        { reportRole && reportData &&
            <div className='row'>
                <div className='col-xxl-6'>
                    <MixedWidget11
                        className='card-xxl-stretch-50 mb-5 mb-xl-8'
                        chartColor='primary'
                        chartHeight='175px'
                        listOnline={reportData.paymentOnline}
                        listOffline={reportData.paymentOffline}
                        totalPayment={reportData.totalPayment}
                    />
                </div>
                <div className='col-xxl-6'>
                    <MixedWidget10
                        className='card-xxl-stretch-50 mb-5 mb-xl-8'
                        chartColor='primary'
                        chartHeight='175px'
                        listPayment={reportData.totalMonthPayment}
                        totalPayment={reportData.totalPayment}
                    />
                </div>
            </div>
        }
        { calendarData &&
            <>
                <Box
                    sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& > *': {
                        m: 1,
                    },
                    }}
                >
                    <FormControl sx={{ m: 1, width: 250 }} size="small">
                        <InputLabel id="demo-select-small-label">Branch</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectBranch}
                            label="Branch"
                            onChange={handleChange}
                        >
                            {subBranch?.map((item) =>
                                <MenuItem key={item.branchID} value={item.branchID}>{item.branchCode} - {item.branchName}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <ButtonGroup aria-label="outlined button group">
                        <Button variant={indexMonth === -1 ? "contained" : "outlined"} 
                            onClick={() => {
                                getCalendar(selectBranch, lastMonth)
                                setIndexMonth(-1)
                            }}>Last month</Button>
                        <Button variant={indexMonth === 0 ? "contained" : "outlined"} 
                            onClick={() => {
                                getCalendar(selectBranch, thisMonth)
                                setIndexMonth(0)
                            }}>This month</Button>
                        <Button variant={indexMonth === 1 ? "contained" : "outlined"} 
                            onClick={() => {
                                getCalendar(selectBranch, nextMonth)
                                setIndexMonth(1)
                            }}>Next month</Button>
                    </ButtonGroup>
                </Box>
                <Box
                    sx={{
                    width: '100%',
                    '& .cold': {
                        backgroundColor: '#b9d5ff91',
                        color: '#1a3e72',
                    },
                    '& .hot': {
                        backgroundColor: '#ff943975',
                        color: '#1a3e72',
                    },
                    }}
                >
                    <DataGrid
                        rows={calendarData}
                        columns={columns}
                        autoHeight
                        hideFooter
                        getCellClassName={(params: GridCellParams<any, any, number>) => {
                        if (params.field === 'roomName' || params.value == null) {
                            return '';
                        }
                        return params.value == 'booked' ? 'hot' : 'cold';
                        }}
                    /> 
                </Box>
                <div style={{margin: '20px', display: 'flex'}}>
                    <div style={{height: '15px', width: '70px', backgroundColor: '#b9d5ff91'}}></div>
                    <b style={{ marginLeft: '10px' }}>Room empty</b>
                    <div style={{ marginLeft: '40px', height: '15px', width: '70px', backgroundColor: '#ff943975' }}></div>
                    <b style={{ marginLeft: '10px' }}>Room booked</b>
                </div>
            </>
        }
    </>
)}

const DashboardWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage />
        </>
    )
}

export {DashboardWrapper}
