import React, {FC, useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {useAuth} from "../auth";
import {GridColumns} from "@mui/x-data-grid";
import {KTSVG} from "../../../_metronic/helpers";
import {ContactData} from "./core/models";
import StripedDataGrid from '../../../core/components/data-grid/StripedDataGridPage';
import CustomPagination from "../../../core/components/data-grid/PaginationPage";
import {queryContact, searchContact, updateContact} from "./core/service";
import {DateTimeToISOstring, FormatDateTimeToLong, GetDateTime, IsEmpty} from "../../../core/utilities/functionBase";
import {ToastMessageOption} from "../../../core/options/toastMessageOption";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import { Alert, Stack } from '@mui/material';
import { AccountStatusWidget } from '../../../core/widgets/AccountStatusWidget';

const statusOptions = [
    { value: 'NEW', label: 'New' },
    { value: 'PROCESS', label: 'Process' },
    { value: 'FINISH', label: 'Finish' }
]


const ContactPage: FC = () => {
    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Contacts',
            path: '/support/contact',
            isSeparator: false,
            isActive: false,
        }
    ]
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const columnRoleGroups: GridColumns = [
        {field: 'contactID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <button className="border-0 rounded bg-transparent"
                               data-bs-toggle="modal"
                               data-bs-target="#kt_modal_contact"
                            onClick={() =>{query(params.value)}}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3'/>
                    </button>
            }
        },
        {field: 'name', width: 200, editable: true, renderHeader: () => (<strong>Name</strong>)},
        {field: 'email', width: 200, editable: true, renderHeader: () => (<strong>Email</strong>)},
        {field: 'phoneNumber', width: 150, editable: true, renderHeader: () => (<strong>Phone Number</strong>)},
        {field: 'status', width: 150, editable: true, renderHeader: () => (<strong>Status</strong>), 
            renderCell: (params) => {return AccountStatusWidget(params.value)}},
        {field: 'dateTimeCreate', width: 220, editable: true, renderHeader: () => (<strong>Create Date time</strong>)}
    ];

    const [contactData, setContactData] = useState<Array<ContactData>>();
    const [loadingButtonSearch, setLoadingButtonSearch] = useState(false)
    const [loadingButtonSaveRole, setLoadingButtonSaveRole] = useState(false)
    const [txtCustomerValue, setCustomerValue] = useState('');
    const [txtStatus, setStatus] = useState('');
    const [txtFromDateTime, setFromDateTime] = useState(DateTimeToISOstring(7));
    const [txtToDateTime, setToDateTime] = useState(DateTimeToISOstring(-1));
    const [txtContactID, setContactID] = useState(0)
    const [contact, setContact] = useState<ContactData>();
    const [updateStatus, setUpdateStatus] = useState('');
    const [supportRole, setSupportRole] = React.useState(false);

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setSupportRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_SUPPORTS')));
        }
    },[])
    
    const search = () => {
        if(!txtFromDateTime || !txtToDateTime){
            toast.info("Please check the data input")
            return;
        }
        
        setLoadingButtonSearch(true)
        searchContact(
            txtCustomerValue, 
            txtStatus, 
            FormatDateTimeToLong(new Date(txtFromDateTime)), 
            FormatDateTimeToLong(new Date(txtToDateTime))
        ).then((response) => {
            setContactData(response)
            setLoadingButtonSearch(false)
        });
    }

    const query = (contact: number) =>{
        setContactID(contact)
        console.log(contact)
        queryContact(
            contact
        ).then((response) => {
            if(response){
                setContact(response)
                setUpdateStatus(response.status)
            }
        });
    }

    const update = () =>{
        setLoadingButtonSaveRole(true)

        console.log(txtContactID)
        updateContact(
            txtContactID,
            updateStatus,
            UserID || 0
        ).then((response) => {
            response ? toast.success(ToastMessageOption.Success)
                     : toast.error(ToastMessageOption.Error)
            setLoadingButtonSaveRole(false)
            search()
        });
    }

    return (
        <>
            { supportRole ? (
                <>
                    <PageTitle breadcrumbs={usersBreadcrumbs}></PageTitle>
                    <div className='card mb-5 mb-xl-10'>
                        <div id='kt_branch_data' className='collapse show'>
                            <div className='card-body border-top p-9'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                        <div className='row mb-1'>
                                            <div className='col-lg-3'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Customer info</label>
                                                <input type="text"
                                                    onChange={(e) => {setCustomerValue(e.target.value)}}
                                                    className="form-control form-control-solid"
                                                    placeholder="enter email, phone number"/>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblBranchCode' 
                                                        className='form-label fs-6'>Status</label>
                                                    <select className='form-select form-select-solid'
                                                            onChange={(e) => {setStatus(e.target.value)}}>
                                                        <option value='' >Select all</option>
                                                        {statusOptions?.map((item) =>
                                                            <option key={item.value} value={item.value} >{item.label}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">From Date time</label>
                                                <input type="datetime-local"
                                                        value={txtFromDateTime}
                                                        onChange={(e) => {setFromDateTime(e.target.value)}}
                                                        className="form-control form-control-solid"/>
                                            </div>
                                            <div className='col-lg-3'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">To Date time</label>
                                                <input type="datetime-local"
                                                        value={txtToDateTime}
                                                        onChange={(e) => {setToDateTime(e.target.value)}}
                                                        className="form-control form-control-solid"/>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-5'>
                                            <div className='mx-auto'>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    onClick={search}
                                                    className='btn btn-primary me-2'
                                                >
                                                    {!loadingButtonSearch && 'Search'}
                                                    {loadingButtonSearch && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        {contactData && (
                                            <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                                <StripedDataGrid
                                                    loading={false}
                                                    rows={contactData}
                                                    columns={columnRoleGroups}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                    }
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    components={{
                                                        Pagination: CustomPagination,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" tabIndex={-1} id="kt_modal_contact">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Contact view</h5>
                                    <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr061.svg"
                                            className="svg-icon svg-icon-2x"
                                        />
                                    </div>
                                </div>

                                {contact &&
                                    <div className="modal-body">
                                        <div>
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Customer name</label>
                                            <input type="text"
                                                readOnly={true}
                                                className="form-control form-control-solid"
                                                value={contact.name}/>
                                        </div>
                                        <div className="mt-5">
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Description</label>
                                            <textarea className="form-control form-control-solid"
                                                    readOnly={true}
                                                    value={contact.content}/>
                                        </div>
                                        <div className="mt-5">
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Role group</label>
                                            <select className="form-select form-select-solid"
                                                    value={updateStatus}
                                                    onChange={(e) => {setUpdateStatus(e.target.value)}}>
                                                {statusOptions?.map((item) =>
                                                    <option key={item.value} value={item.value} >{item.label}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="modal-footer">
                                    <button
                                        id="closeButtonRole"
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal">
                                        Close
                                    </button>
                                    <button type="button"
                                            onClick={update}
                                            className="btn btn-primary">
                                        {!loadingButtonSaveRole && 'Save'}
                                        {loadingButtonSaveRole && (
                                            <span className='indicator-progress' style={{display: 'block'}}>Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            ) }
        </>
    )
}

export {ContactPage}
