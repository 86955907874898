import React, {FC, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {ToastMessageOption} from "../../../../../core/options/toastMessageOption";
import {SearchRoleGroup, SaveRoleGroup, QueryRoleGroup} from "../core/service";
import {RoleGroupData} from "../core/models";
import {KTSVG} from "../../../../../_metronic/helpers";
import CustomPagination from "../../../../../core/components/data-grid/PaginationPage";
import StripedDataGrid from "../../../../../core/components/data-grid/StripedDataGridPage";
import {GridColumns} from "@mui/x-data-grid";
import {useAuth} from "../../../auth";
import PopupConfirmWidget from "../../../../../core/widgets/PopupConfirmWidget";

const RoleGroupPage: FC = () => {
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const columnRoleGroups: GridColumns = [
        {field: 'roleGroupID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <>
                    <button className="border-0 rounded bg-transparent"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_roleGroup"
                            onClick={() => {
                                btnPopupRoleGroup(params.value)
                            }}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3'/>
                    </button>
                    <button className="border-0 rounded bg-transparent"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_confirm_roleGroup"
                            onClick={() => {
                                setTxtGroupID(params.value)
                            }}>
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='text-danger svg-icon-3'/>
                    </button>
                </>
            }
        },
        {field: 'roleGroupName', width: 200, editable: true, renderHeader: () => (<strong>Role group name</strong>)},
        {field: 'description', width: 200, editable: true, renderHeader: () => (<strong>Description</strong>)},
        {field: 'isDisable', width: 180, editable: true, renderHeader: () => (<strong>Status</strong>)},
        {field: 'userIDModify', width: 180, editable: true, renderHeader: () => (<strong>User modify</strong>)},
        {field: 'dateTimeModify', width: 220, editable: true, renderHeader: () => (<strong>Date modify</strong>)}
    ];

    const [roleGroupData, setRoleGroupData] = useState<Array<RoleGroupData>>();
    const [loadingButtonSearchRoleGroup, setLoadingButtonSearchRoleGroup] = useState(false)
    const [loadingButtonSaveRoleGroup, setLoadingButtonSaveRoleGroup] = useState(false)
    const [txtInputRoleGroup, setInputRoleGroup] = useState('');
    const [txtRoleGroupID, setTxtGroupID] = useState('');
    const [txtRoleGroupName, setTxtRoleGroupName] = useState('');
    const [txtRoleGroupDescription, setTxtRoleGroupDescription] = useState('');
    const [txtValRoleGroupName, setTxtValRoleGroupName] = useState('');
    const [txtValRoleGroupDescription, setTxtValRoleGroupDescription] = useState('');

    const validateFormRoleGroup = () => {
        let result = true
        if(!txtRoleGroupName){
            result = false
            setTxtValRoleGroupName("This field is required")
        }else{
            setTxtValRoleGroupName("")
        }

        if(!txtRoleGroupDescription){
            result = false
            setTxtValRoleGroupDescription("This field is required")
        }else{
            setTxtValRoleGroupDescription("")
        }

        return result
    }

    const searchRoleGroup = () => {
        setLoadingButtonSearchRoleGroup(true)
        SearchRoleGroup(txtInputRoleGroup).then((response) => {
            if(response){
                setRoleGroupData(response)
            }else {
                toast.info("Data invalid")
            }
            setLoadingButtonSearchRoleGroup(false)
        });
    }

    const createRoleGroup = () => {
        if(validateFormRoleGroup()){
            setLoadingButtonSaveRoleGroup(true)
            SaveRoleGroup(txtRoleGroupID, 
                    txtRoleGroupName, 
                    txtRoleGroupDescription,
                    false, 
                    UserID).then((response) => {
                if(response){
                    toast.success(ToastMessageOption.Success);
                    let close = document.getElementById('closeButtonRoleGroup')
                    if(close){
                        close.click()
                    }
                }else {
                    toast.error(ToastMessageOption.Error);
                }
                setLoadingButtonSaveRoleGroup(false);
                searchRoleGroup();
            });
        }
    }

    const btnPopupRoleGroup = (roleGroupId: string) => {
        if(roleGroupId) {
            QueryRoleGroup(roleGroupId).then((response) => {
                setTxtGroupID(response.roleGroupID.toString())
                setTxtRoleGroupName(response.roleGroupName)
                setTxtRoleGroupDescription(response.description)
            });
        }else{
            setTxtGroupID('')
            setTxtRoleGroupName('')
            setTxtRoleGroupDescription('')
        }
    }

    const btnDeleteRoleGroup = () =>{
        SaveRoleGroup(txtRoleGroupID,
            txtRoleGroupName,
            txtRoleGroupDescription,
            true,
            UserID).then((response) => {
            if(response){
                toast.success(ToastMessageOption.Success);
            }else {
                toast.error(ToastMessageOption.Error);
            }
        });
    }

    return (
        <>
            {PopupConfirmWidget(() => {btnDeleteRoleGroup()}, "kt_modal_confirm_roleGroup")}
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_role_group_data'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Role group</h3>
                    </div>
                </div>
                <div id='kt_role_group_data' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        <div className='d-flex flex-wrap align-items-center'>
                            <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                <div className='row mb-1'>
                                    <div className='col-lg-4'>
                                        <div>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label">Input role group</label>
                                            <input type="email"
                                                   onChange={(e) => {setInputRoleGroup(e.target.value)}}
                                                   className="form-control form-control-solid"
                                                   placeholder="role group"/>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='mx-auto'>
                                        <button
                                            id='btnReloadSecure'
                                            type='button'
                                            onClick={searchRoleGroup}
                                            className='btn btn-primary me-2'
                                        >
                                            {!loadingButtonSearchRoleGroup && 'Search'}
                                            {loadingButtonSearchRoleGroup && (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <button
                                            id='btnReloadSecure'
                                            type='button'
                                            className='btn btn-success me-2'
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_roleGroup"
                                            onClick={() => {btnPopupRoleGroup('')}}
                                        >
                                            Create RoleGroup
                                        </button>
                                        {/* Model */}
                                        <div className="modal fade" tabIndex={-1} id="kt_modal_roleGroup">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Create Role Group</h5>
                                                        <div
                                                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <KTSVG
                                                                path="/media/icons/duotune/arrows/arr061.svg"
                                                                className="svg-icon svg-icon-2x"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="modal-body">
                                                        <input type="hidden" id="txtRoleId" value={txtRoleGroupID}></input>
                                                        <div className="mt-5">
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label">Role group name</label>
                                                            <input type="text"
                                                                   value={txtRoleGroupName}
                                                                   onChange={(e) => {setTxtRoleGroupName(e.target.value)}}
                                                                   className="form-control form-control-solid"
                                                                   placeholder="Role group name"/>
                                                            <span style={{ color: "red" }}>{txtValRoleGroupName}</span>
                                                        </div>
                                                        <div className="mt-5">
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label">Description</label>
                                                            <textarea className="form-control form-control-solid"
                                                                      value={txtRoleGroupDescription}
                                                                      onInput={(e) => setTxtRoleGroupDescription((e.target as HTMLInputElement).value)}
                                                                      placeholder="description"/>
                                                            <span style={{ color: "red" }}>{txtValRoleGroupDescription}</span>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            id="closeButtonRoleGroup"
                                                            type="button"
                                                            className="btn btn-light"
                                                            data-bs-dismiss="modal">
                                                            Close
                                                        </button>
                                                        <button type="button"
                                                                onClick={createRoleGroup}
                                                                className="btn btn-primary">
                                                            {!loadingButtonSaveRoleGroup && 'Save'}
                                                            {loadingButtonSaveRoleGroup && (
                                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {roleGroupData && (
                                    <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                        <StripedDataGrid
                                            loading={false}
                                            rows={roleGroupData}
                                            columns={columnRoleGroups}
                                            getRowClassName={(params) =>
                                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                            }
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            components={{
                                                Pagination: CustomPagination,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {RoleGroupPage}
