import { Post } from '../../../../core/services/serviceBase';
import { ServiceOption } from '../../../../core/options/serviceOption';
import {CityModel, ContactData, TouristAttractionData, TouristPlaceData} from "./models";

const API_URL = process.env.REACT_APP_API_URL
export const GET_CITY_URL = `${API_URL}/Support/GetCity`
export const CONTACT_SEARCH_URL = `${API_URL}/Support/SearchContact`
export const CONTACT_QUERY_URL = `${API_URL}/Support/QueryContact`
export const CONTACT_UPDATE_URL = `${API_URL}/Support/UpdateContact`
export const TOURIST_ATTRACTION_SEARCH_URL = `${API_URL}/Support/SearchTouristAttractions`
export const TOURIST_ATTRACTION_QUERY_URL = `${API_URL}/Support/QueryTouristAttraction`
export const TOURIST_ATTRACTION_CREATE_URL = `${API_URL}/Support/CreateTouristAttraction`
export const TOURIST_ATTRACTION_UPDATE_URL = `${API_URL}/Support/UpdateTouristAttraction`
export const TOURIST_PLACE_SEARCH_URL = `${API_URL}/Support/SearchTouristPlaces`
export const TOURIST_PLACE_QUERY_URL = `${API_URL}/Support/QueryTouristPlace`
export const TOURIST_PLACE_UPDATE_URL = `${API_URL}/Support/UpdateTouristPlace`
export const TOURIST_PLACE_CREATE_URL = `${API_URL}/Support/CreateTouristPlace`
export const TOURIST_PLACE_IMAGE_CREATE_URL = `${API_URL}/Support/CreateTouristImagePlace`
export const TOURIST_PLACE_IMAGE_DELETE_URL = `${API_URL}/Support/DeleteTouristImagePlace`

export async function searchContact(
    contactInfo: string, 
    status: string, 
    fromDateTime: number, 
    toDateTime: number) 
{
    const response = await Post(CONTACT_SEARCH_URL, {
        contactInfo,
        status,
        fromDateTime,
        toDateTime
    })
    let contactData = {} as Array<ContactData>;

    if(response?.responseCode === ServiceOption.Success){
        contactData = response.responseData;
    }

    return contactData;
}

export async function queryContact(contactID: number) {
    const response = await Post(CONTACT_QUERY_URL, { contactID })
    let contactData = {} as ContactData;

    if(response?.responseCode === ServiceOption.Success){
        contactData = response.responseData;
    }

    return contactData
}

export async function updateContact(contactID: number, status: string, userRequest: number) {
    const response = await Post(CONTACT_UPDATE_URL, { contactID, status, userRequest })
    return response?.responseCode === ServiceOption.Success
}

export async function getCity() {
    const response = await Post(GET_CITY_URL, {})
    let cityData = {} as Array<CityModel>;

    if(response?.responseCode === ServiceOption.Success){
        cityData = response.responseData;
    }

    return cityData
}

export async function searchTouristAttraction(
    touristAttractionName: string,
    city: string,
    month: number)
{
    const response = await Post(TOURIST_ATTRACTION_SEARCH_URL, {
        touristAttractionName,
        city,
        month
    })
    let touristAttractionData = {} as Array<TouristAttractionData>;

    if(response?.responseCode === ServiceOption.Success){
        touristAttractionData = response.responseData;
    }

    return touristAttractionData;
}

export async function queryTouristAttraction(touristAttractionID: number) {
    const response = await Post(TOURIST_ATTRACTION_QUERY_URL, { touristAttractionID })
    let touristAttractionData = {} as TouristAttractionData;

    if(response?.responseCode === ServiceOption.Success){
        touristAttractionData = response.responseData;
    }

    return touristAttractionData
}

export async function createTouristAttraction(
    touristAttractionName: string,
    cityCode: string,
    imageUrl: string,
    content: string,
    month: number, 
    userRequest: number) 
{
    const response = await Post(TOURIST_ATTRACTION_CREATE_URL, 
        { touristAttractionName,
            cityCode,
            imageUrl,
            content,
            month,
            userRequest
        })

    return response?.responseCode === ServiceOption.Success
}

export async function updateTouristAttraction(
    touristAttractionID: number,
    touristAttractionName: string,
    cityCode: string,
    imageUrl: string,
    content: string,
    month: number,
    userRequest: number)
{
    const response = await Post(TOURIST_ATTRACTION_UPDATE_URL,
        {
            touristAttractionID,
            touristAttractionName,
            cityCode,
            imageUrl,
            content,
            month,
            userRequest
        })
    
    return response?.responseCode === ServiceOption.Success
}

export async function searchTouristPlaces(
    touristPlaceName: string,
    cityCode: string)
{
    const response = await Post(TOURIST_PLACE_SEARCH_URL, {
        touristPlaceName,
        cityCode
    })
    let touristPlaceData = {} as Array<TouristPlaceData>;

    if(response?.responseCode === ServiceOption.Success){
        touristPlaceData = response.responseData;
    }

    return touristPlaceData;
}

export async function queryTouristPlace(touristPlaceID: number) {
    const response = await Post(TOURIST_PLACE_QUERY_URL, { touristPlaceID })
    let touristPlaceData = {} as TouristPlaceData;

    if(response?.responseCode === ServiceOption.Success){
        touristPlaceData = response.responseData;
    }

    return touristPlaceData
}

export async function updateTouristPlace(
    touristPlaceID: number,
    touristPlaceName: string,
    content: string,
    cityCode: string,
    phoneNumber: string,
    address: string,
    userRequest: number)
{
    const response = await Post(TOURIST_PLACE_UPDATE_URL, {
        touristPlaceID,
        touristPlaceName,
        content,
        cityCode,
        phoneNumber,
        address,
        userRequest
    })
    
    return response?.responseCode === ServiceOption.Success
}

export async function createTouristPlace(
    touristPlaceName: string,
    content: string,
    cityCode: string,
    phoneNumber: string,
    address: string,
    userRequest: number)
{
    const response = await Post(TOURIST_PLACE_CREATE_URL, {
        touristPlaceName,
        content,
        cityCode,
        phoneNumber,
        address,
        userRequest
    })
    
    let touristPlaceData = {} as TouristPlaceData;

    if(response?.responseCode === ServiceOption.Success){
        touristPlaceData = response.responseData;
    }

    return touristPlaceData
}

export async function createTouristPlaceImage(
    touristPlaceID: number,
    imageUrl: string,
    userRequest: number)
{
    const response = await Post(TOURIST_PLACE_IMAGE_CREATE_URL, {
        touristPlaceID,
        imageUrl,
        userRequest
    })
    
    return response?.responseCode === ServiceOption.Success
}

export async function deleteTouristPlaceImage(touristPlaceImageID: number)
{
    const response = await Post(TOURIST_PLACE_IMAGE_DELETE_URL, {
        touristPlaceImageID
    })
    
    return response?.responseCode === ServiceOption.Success
}



