import axios from 'axios';
import { GetGuid, GetDateTime } from '../utilities/functionBase';
import { PatternOption } from '../options/patternOption';
import { RequestServiceData, ResponseServiceData } from '../models/serviceData'

function requestServiceData(requestData: object) {
  let data : RequestServiceData = {
    requestID: GetGuid(),
    requestDateTime: GetDateTime(PatternOption.DateTime),
    requestData: requestData,
  };

  return data;
}

// POST
export async function Post(url: string, requestData: object) {
  let serviceData : RequestServiceData;
  serviceData = requestServiceData(requestData);

  console.log('Request url: ', url);
  //console.log('Request data: ', JSON.stringify(serviceData));

  try {
    const { data } = await axios.post<ResponseServiceData>(url, serviceData)
    console.log(JSON.stringify(data, null, 4));
    return data;
  } 
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } 
    else {
      console.log('unexpected error: ', error);
    }
    return null;
  }
}

// GET
export async function Get(url: string) {
    //console.log('Request url: ', url);

    try {
        const { data } = await axios.get(url)
        //console.log(JSON.stringify(data, null, 4));
        return data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
        }
        else {
            console.log('unexpected error: ', error);
        }
        return null;
    }
}