import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../errors/ErrorsPage'
import {App} from '../App'
import { IsEmpty } from '../../core/utilities/functionBase'
import {AuthPage, Logout, useAuth} from "../admin/auth";
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const {currentUser} = useAuth()
    return (
        <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
            <Route element={<App />}>
                <Route path='error/*' element={<ErrorsPage />} />
                <Route path='logout' element={<Logout />} />
                {currentUser && !IsEmpty(currentUser) ? (
                <>
                    <Route path='/*' element={<PrivateRoutes />} />
                    <Route index element={<Navigate to='/dashboard' />} />
                </>
                ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
        </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
