import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import RoleRouter from "../admin/manager/roles/RoleRouter";
import BranchRouter from "../admin/manager/branches/BranchRouter";
import {ContactPage} from "../admin/support/ContactPage";
import {TouristAttractionPage} from "../admin/support/TouristAttractionPage";
import { TouristPlacePage } from '../admin/support/TouristPlacePage'
import { TouristPlaceInquiryPage } from '../admin/support/TouristPlaceInquiryPage'
import { RoomPage } from '../admin/book/room/RoomPage'
import { RoomInquiryPage } from '../admin/book/room/RoomInquiryPage'
import { ServicePage } from '../admin/book/service/ServicePage'
import { ServiceInquiryPage } from '../admin/book/service/ServiceInquiryPage'
import { OrderPage } from '../admin/book/order/OrderPage'

const PrivateRoutes = () => {
    const UsersPage = lazy(() => import('../admin/manager/users/UserRouter'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                <Route path='dashboard' element={<DashboardWrapper />} />
                <Route path='admin/user-management/*' 
                       element={
                            <SuspensedView>
                                <UsersPage />
                            </SuspensedView>
                        }
                />
                <Route
                    path='admin/branch/*'
                    element={
                        <SuspensedView>
                        <BranchRouter />
                        </SuspensedView>
                    }
                />
                <Route path='admin/role' element={<RoleRouter />} />
                <Route path='support/contact' element={<ContactPage />} />
                <Route path='support/tourist-attraction' element={<TouristAttractionPage />} />
                <Route path='support/tourist-places' element={<TouristPlacePage />} />
                <Route path='support/tourist-place-inquiry/:touristPlaceID' element={<TouristPlaceInquiryPage />} />
                <Route path='book/room' element={<RoomPage />} />
                <Route path='book/room-inquiry/:roomID' element={<RoomInquiryPage />} />
                <Route path='book/service' element={<ServicePage />} />
                <Route path='book/service-inquiry/:roomID' element={<ServiceInquiryPage />} />
                <Route path='book/order-search' element={<OrderPage />} />
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
