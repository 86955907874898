import {BranchData, BranchPermissionData} from "./models";
import {ServiceOption} from "../../../../../core/options/serviceOption";
import {Get, Post} from "../../../../../core/services/serviceBase";

const API_URL = process.env.REACT_APP_API_URL
export const SEARCH_BRANCH_URL = `${API_URL}/Branch/Search`
export const QUERY_BRANCH_URL = `${API_URL}/Branch/Query`
export const GET_BRANCH_URL = `${API_URL}/Branch/GetBranches`
export const SAVE_BRANCH_URL = `${API_URL}/Branch/Save`

export const SEARCH_BRANCH_PERMISSION_URL = `${API_URL}/Branch/SearchBranchPermission`
export const SAVE_BRANCH_PERMISSION_URL = `${API_URL}/Branch/SaveBranchPermission`

export async function SearchBranch(branchName: string) {
    const response = await Post(SEARCH_BRANCH_URL, {
        branchName
    })
    let branchData = {} as Array<BranchData>;

    if(response?.responseCode === ServiceOption.Success){
        branchData = response.responseData;
    }

    return branchData;
}

export async function QueryBranch(branchId: string) {
    const response = await Post(QUERY_BRANCH_URL, {
        branchId
    })
    let branchData = {} as BranchData;

    if(response?.responseCode === ServiceOption.Success){
        branchData = response.responseData;
    }

    return branchData;
}

export async function GetBranches() {
    const response = await Get(GET_BRANCH_URL)
    let authData = {} as Array<BranchData>;

    if(response?.responseCode === ServiceOption.Success){
        authData = response.responseData;
    }

    return authData;
}

export async function SaveBranch(
    branchID: string,
    parentID: string, 
    branchName: string, 
    branchCode: string,
    displayName: string,
    email: string,
    phoneNumber: string,
    addressLine01: string,
    cityCode: string,
    countryCode: string,
    isHeadOffice: boolean,
    isDisable = false,
    userID: number | undefined) {
    const response = await Post(SAVE_BRANCH_URL, {
        branchID,
        parentID,
        branchName,
        branchCode,
        displayName,
        email,
        phoneNumber,
        addressLine01,
        cityCode,
        countryCode,
        isHeadOffice,
        isDisable,
        userID
    })

    return response?.responseCode === ServiceOption.Success
}

export async function SearchBranchPermission(branchId: string) {
    const response = await Post(SEARCH_BRANCH_PERMISSION_URL, {
        branchId
    })
    let authData = {} as Array<BranchPermissionData>;

    if(response?.responseCode === ServiceOption.Success){
        authData = response.responseData;
    }

    return authData;
}

export async function SaveBranchPermission(branchId: string, roleGroups: Array<string>, userID: number | undefined) {
    const response = await Post(SAVE_BRANCH_PERMISSION_URL, {
        branchId,
        roleGroups,
        userID
    })
    
    return response?.responseCode === ServiceOption.Success
}