import React, {FC, useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../auth";
import {GridColumns} from "@mui/x-data-grid";
import {KTSVG} from "../../../../_metronic/helpers";
import {ServiceData} from "./core/models";
import StripedDataGrid from '../../../../core/components/data-grid/StripedDataGridPage';
import CustomPagination from "../../../../core/components/data-grid/PaginationPage";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import { Link, useNavigate } from 'react-router-dom';
import { deleteService, searchService } from './core/service';
import PopupConfirmWidget from '../../../../core/widgets/PopupConfirmWidget';
import { toast } from 'react-toastify';
import { ToastMessageOption } from '../../../../core/options/toastMessageOption';
import { IsEmpty } from '../../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';

const ServicePage: FC = () => {
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const subBranch = currentUser?.subBranch

    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Service',
            path: '/book/service',
            isSeparator: false,
            isActive: false,
        }
    ]
    const navigate = useNavigate();
    const columnRoleGroups: GridColumns = [
        {field: 'serviceID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
                return <>
                    <Link to={`/book/service-inquiry/${params.value}`}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3'/>
                    </Link>
                    <button className="border-0 rounded bg-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_delete_service"
                        onClick={() =>{setTxtServiceID(params.value)}}>
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='text-danger svg-icon-3'/>
                    </button>
                </>
                
            }
        },
        {field: 'serviceName', width: 150, editable: true, renderHeader: () => (<strong>Name</strong>)},
        {field: 'price', width: 150, editable: true, renderHeader: () => (<strong>Price</strong>)},
        {field: 'cityCode', width: 100, editable: true, renderHeader: () => (<strong>City Code</strong>)},
        {field: 'phoneNumber', width: 200, editable: true, renderHeader: () => (<strong>Phone Number</strong>)},
        {field: 'countSearch', width: 100, editable: true, renderHeader: () => (<strong>Total search</strong>)},
        {field: 'countRental', width: 100, editable: true, renderHeader: () => (<strong>Total booking</strong>)},
        {field: 'dateTimeModify', width: 200, editable: true, renderHeader: () => (<strong>Date time modify</strong>)}
    ];
    const [serviceData, setServiceData] = React.useState<Array<ServiceData>>();
    const [txtServiceID, setTxtServiceID] = useState(0)
    const [loadingButtonSearch, setLoadingButtonSearch] = useState(false)
    const [txtServiceName, setTxtServiceName] = useState('');
    const [txtPhoneNumber, setTxtPhoneNumber] = useState('');
    const [bookingRole, setBookingRole] = React.useState(false);

    useEffect(() => {
        if(currentUser && currentUser.userRoles){
            setBookingRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_BOOKING')));
        }
    },[])
    
    const search = () => {
        if(subBranch){
            setLoadingButtonSearch(true)
            searchService(
                txtServiceName,
                txtPhoneNumber,
                subBranch.map(item => item.branchID)
            ).then((response) => {
                setServiceData(response)
                setLoadingButtonSearch(false)
            });
        }
    }

    const create = () => {
        navigate("/book/service-inquiry/0");
    }

    const btnDelete = () =>{
        deleteService(txtServiceID, UserID || 0).then((response) => {
            if(response){
                toast.success(ToastMessageOption.Success);
            } else {
                toast.error(ToastMessageOption.Error);
            }
        });
    }

    return (
        <>
            { bookingRole ? (
                <>
                    {PopupConfirmWidget(() => {btnDelete()}, "kt_modal_delete_service")}
                    <PageTitle breadcrumbs={usersBreadcrumbs}></PageTitle>
                    <div className='card mb-5 mb-xl-10'>
                        <div id='kt_branch_data' className='collapse show'>
                            <div className='card-body border-top p-9'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                        <div className='row mb-1'>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Service name</label>
                                                <input type="text"
                                                        value={txtServiceName}
                                                        onChange={(e) => {
                                                            setTxtServiceName(e.target.value)
                                                        }}
                                                        className="form-control form-control-solid"
                                                        placeholder="Service name"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor="exampleFormControlInput1"
                                                        className="form-label">Phone number</label>
                                                    <input type="text"
                                                        value={txtPhoneNumber}
                                                        onChange={(e) => {
                                                            setTxtPhoneNumber(e.target.value)
                                                        }}
                                                        className="form-control form-control-solid"
                                                        placeholder="Phone number"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-5'>
                                            <div className='mx-auto'>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    onClick={search}
                                                    className='btn btn-primary me-2'
                                                >
                                                    {!loadingButtonSearch && 'Search'}
                                                    {loadingButtonSearch && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                                <button
                                                    id='btnReloadSecure'
                                                    type='button'
                                                    className='btn btn-success me-2'
                                                    onClick={() => create()}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                        {serviceData && (
                                            <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                                <StripedDataGrid
                                                    loading={false}
                                                    rows={serviceData}
                                                    columns={columnRoleGroups}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                    }
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    components={{
                                                        Pagination: CustomPagination,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}
        </>
    )
}

export {ServicePage}
