import React, {FC, useEffect, useRef, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {useAuth} from "../../auth";
import {KTSVG} from "../../../../_metronic/helpers";
import {ToastMessageOption} from "../../../../core/options/toastMessageOption";
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import { useNavigate, useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../../../../_metronic/assets/css/style.css';
import { LightBoxData } from '../../support/core/models';

import {CityModel, ServiceData} from "./core/models";
import { createService, deleteServiceImage, getCity, queryService, updateService, createServiceImage } from './core/service';
import { LoadingPageData } from '../../../../core/models/baseData';
import { LoadingPage } from '../../../../core/components/LoadingPage';
import { IsEmpty } from '../../../../core/utilities/functionBase';
import { Alert, Stack } from '@mui/material';

const ServiceInquiryPage: FC = () => {
    const usersBreadcrumbs: Array<PageLink> = [
        {
            title: 'Service Inquiry',
            path: '/Book/service-inquiry',
            isSeparator: false,
            isActive: false,
        }
    ]
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const subBranch = currentUser?.subBranch
    const params = useParams();
    const navigate = useNavigate();
    const childRef = useRef<LoadingPageData>(null)
    const [isOpen, setIsOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [imgIndex, setImgIndex] = useState(0);
    const [txtServiceName, setTxtServiceName] = useState('');
    const [txtPrice, setTxtPrice] = useState('');
    const [txtPhoneNumber, setTxtPhoneNumber] = useState('');
    const [txtBranch, setTxtBranch] = useState('1');
    const [txtCity, setTxtCity] = useState('054');
    const [txtAddress, setTxtAddress] = useState('');
    const [txtContent, setTxtContent] = useState('');
    const [txtDisable, setTxtDisable] = useState('');
    const [txtDateTimeModify, setTxtDateTimeModify] = useState('');
    const [txtUserModify, setTxtUserModify] = useState('');
    const [images, setImages] = useState<Array<LightBoxData>>();
    const [serviceData, setServiceData] = React.useState<ServiceData>();
    const [cityData, setCityData] = React.useState<Array<CityModel>>();

    const [loadingButtonSaveImage, setLoadingButtonSaveImage] = useState(false)
    const [loadingButtonSave, setLoadingButtonSave] = useState(false)
    const [txtImageUrl, setImageUrl] = useState('');
    const [bookingRole, setBookingRole] = React.useState(false);

    useEffect(() => {
        childRef.current?.handleToggle();

        if(currentUser && currentUser.userRoles){
            setBookingRole(!IsEmpty(currentUser.userRoles.find(item => item.roleName == 'EMP_BOOKING')));
        }
        
        getCity().then((response) => {
            setCityData(response)
        })

        let roomID = Number(params.roomID)
        if(params.roomID &&  roomID > 0) {
            query(roomID);
        }
    },[])

    const query = (serviceID: number) => {
        queryService(serviceID).then((response) => {
            if(response){
                setIsUpdate(true)
                setServiceData(response)
                setTxtServiceName(response.serviceName)
                setTxtPrice(response.price.toString())
                setTxtPhoneNumber(response.phoneNumber)
                setTxtBranch(response.branchID.toString())
                setTxtCity(response.cityCode)
                setTxtAddress(response.address)
                setTxtContent(response.content)
                setTxtDisable(response.isDisable)
                setTxtDateTimeModify(response.dateTimeModify)
                setTxtUserModify(response.userIDModify)

                const list: Array<LightBoxData> = [];
                let i = 0;
                for (const image of response.serviceImages) {
                    console.log(image.imageUrl)
                    let imageData: LightBoxData = {
                        id: i,
                        imageId: image.roomImageID,
                        title: `Image ${i + 1}`,
                        caption: `Image ${i + 1}`,
                        url: image.imageUrl
                    }

                    list.push(imageData)
                    i++;
                }

                setImages(list);
                childRef.current?.handleClose();
            }
        })
    }
   
    const save = () =>{
        setLoadingButtonSave(true)
        if(isUpdate && serviceData){
            updateService(
                serviceData.serviceID,
                txtServiceName,
                txtContent,
                Number(txtPrice),
                txtCity,
                Number(txtBranch),
                txtPhoneNumber,
                txtAddress,
                "NEW",
                UserID || 0
            ).then((response) => {
                setLoadingButtonSave(false)
                if(response) {
                    toast.success(ToastMessageOption.Success)
                    query(serviceData.serviceID)
                } else {
                    toast.error(ToastMessageOption.Error)
                }
            });
        } else {
            createService(
                txtServiceName,
                txtContent,
                Number(txtPrice),
                txtCity,
                Number(txtBranch),
                txtPhoneNumber,
                txtAddress,
                "NEW",
                UserID || 0
            ).then((response) => {
                if(response){
                    toast.success(ToastMessageOption.Success)
                    navigate(`/book/service-inquiry/${response.serviceID}`);
                    query(response.serviceID)
                } else {
                    toast.error(ToastMessageOption.Error)
                }
                setLoadingButtonSave(false)
            });
        }
    }

    const createImage = () =>{
        setLoadingButtonSaveImage(true)
        if(isUpdate && serviceData){
            createServiceImage(
                serviceData.serviceID,
                txtImageUrl,
                UserID || 0
            ).then((response) => {
                setLoadingButtonSaveImage(false)
                if(response) {
                    toast.success(ToastMessageOption.Success)
                    query(serviceData.serviceID)
                } else {
                    toast.error(ToastMessageOption.Error)
                }
                setImageUrl('')
            });
        }
    }

    const deleteImage = (roomImageID: number) =>{
        if(isUpdate && serviceData){
            deleteServiceImage(roomImageID).then((response) => {
                if(response) {
                    toast.success(ToastMessageOption.Success)
                    query(serviceData.serviceID)
                } else {
                    toast.error(ToastMessageOption.Error)
                }
            });
        }
    }

    return (
        <>
            { bookingRole ? (
                <>
                    <LoadingPage ref={childRef} />
                    <PageTitle breadcrumbs={usersBreadcrumbs}></PageTitle>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_tourist_places'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Information</h3>
                            </div>
                        </div>
                        <div id='kt_tourist_places' className='collapse show'>
                            <div className='card-body border-top p-9'>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <div className={'flex-row-fluid'}>
                                        <div className='row mb-10'>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Room name</label>
                                                <input type="text"
                                                    value={txtServiceName}
                                                    onChange={(e) => {
                                                        setTxtServiceName(e.target.value)
                                                    }}
                                                    className="form-control form-control-solid"
                                                    placeholder="Room name"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Phone number</label>
                                                <input type="number"
                                                        value={txtPhoneNumber}
                                                        onChange={(e) => {
                                                            setTxtPhoneNumber(e.target.value)}
                                                        }
                                                        className="form-control form-control-solid"
                                                        placeholder="Phone number"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Price</label>
                                                <input type="number"
                                                        value={txtPrice}
                                                        onChange={(e) => {
                                                            setTxtPrice(e.target.value)}
                                                        }
                                                        className="form-control form-control-solid"
                                                        placeholder="Price"/>
                                            </div>
                                        
                                        </div>
                                        <div className='row mb-10'>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblBranch'
                                                        className='form-label fs-6'>Branch</label>
                                                    <select className='form-select form-select-solid'
                                                        value={txtBranch}
                                                        onChange={(e) => {
                                                            setTxtBranch(e.target.value)}
                                                        }>
                                                        {subBranch?.map((item) =>
                                                            <option key={item.branchID} value={item.branchID} >
                                                                {item.branchCode} - {item.branchName}
                                                            </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-8'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Address</label>
                                                <textarea className="form-control form-control-solid"
                                                        value={txtAddress}
                                                        onChange={(e) => {
                                                            setTxtAddress(e.target.value)}
                                                        }/>
                                            </div>
                                        </div>
                                        <div className='row mb-10'>
                                            <div className='col-lg-4'>
                                                <div className='fv-row mb-0'>
                                                    <label htmlFor='lblCity'
                                                        className='form-label fs-6'>City</label>
                                                    <select className='form-select form-select-solid'
                                                        value={txtCity}
                                                        onChange={(e) => {
                                                            setTxtCity(e.target.value)}
                                                        }>
                                                        {cityData?.map((item) =>
                                                            <option key={item.cityID} value={item.cityCode} >
                                                                {item.cityCode} - {item.cityName}
                                                            </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-8'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Content</label>
                                                <textarea className="form-control form-control-solid"
                                                        value={txtContent}
                                                        rows={4}
                                                        onChange={(e) => {
                                                            setTxtContent(e.target.value)}
                                                        }/>
                                            </div>
                                        </div>
                                        <div className='row mb-10'>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                    className="form-label">Status</label>
                                                <input type="text"
                                                        value={txtDisable}
                                                        onChange={(e) => {
                                                                setTxtDisable(e.target.value)
                                                        }}
                                                        readOnly
                                                        className="form-control form-control-solid"
                                                        placeholder="Status"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                        className="form-label">Date time modify</label>
                                                <input type="text"
                                                        value={txtDateTimeModify}
                                                        onChange={(e) => {
                                                                setTxtDateTimeModify(e.target.value)
                                                        }}
                                                        readOnly
                                                        className="form-control form-control-solid"
                                                        placeholder="Date time modify"/>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label htmlFor="exampleFormControlInput1"
                                                        className="form-label">User modify</label>
                                                <input type="text"
                                                        value={txtUserModify}
                                                        onChange={(e) => {
                                                                setTxtUserModify(e.target.value)
                                                        }}
                                                        readOnly
                                                        className="form-control form-control-solid"
                                                        placeholder="User modify"/>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-5'>
                                            <div className='mx-auto'>
                                                <button type='button'
                                                    className='btn btn-primary'
                                                    onClick={() => save()}
                                                >
                                                    {!loadingButtonSave && 'Save'}
                                                    {loadingButtonSave && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                                                        Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isUpdate &&
                        <div className='card mb-5 mb-xl-10'>
                            <div
                                className='card-header border-0 cursor-pointer'
                                role='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_list_images'
                            >
                                <div className='card-title m-0'>
                                    <h3 className='fw-bolder m-0'>List Images</h3>
                                </div>
                            </div>

                            <div id='kt_list_images' className='collapse show'>
                                <div className='card-body border-top p-9'>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <div className={'flex-row-fluid'}>
                                            <div className='row'>
                                                {images?.map((item) =>
                                                    <div className='col-lg-3 mb-10'>
                                                        <div className="image-lightbox-container">
                                                            <img src={item.url} alt="Avatar" className="image-lightbox-image"/>
                                                            <div className="d-flex image-lightbox-middle">
                                                                <button type='button'
                                                                    className='btn btn-primary me-2'
                                                                    onClick={() => {
                                                                        console.log(item.id)
                                                                        setImgIndex(item.id)
                                                                        setIsOpen(true)
                                                                    }}
                                                                >
                                                                    View
                                                                </button>
                                                                <button type='button'
                                                                    className='btn btn-danger'
                                                                    onClick={() => deleteImage(item.imageId)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='col-lg-3 mb-10'>
                                                    <div className="image-lightbox-container">
                                                        <img src="https://static.thenounproject.com/png/396915-200.png" 
                                                            alt="Avatar" 
                                                            className="image-lightbox-image"
                                                            style={{ width: '150px', height: '150px', margin: 'auto'}}/>
                                                        <div style={{ textAlign: 'center', marginTop: '5px', fontSize: '16px'}}>
                                                            <button type='button'
                                                                className='btn btn-success'
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#kt_modal_tourist_place"
                                                            >
                                                                Add image
                                                            </button>
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {isOpen && images && (
                                            <Lightbox
                                                imageTitle={images[imgIndex].title}
                                                imageCaption={images[imgIndex].caption}
                                                mainSrc={images[imgIndex].url}
                                                nextSrc={images[(imgIndex + 1) % images.length].url}
                                                prevSrc={images[(imgIndex + images.length - 1) % images.length].url}
                                                onCloseRequest={() => setIsOpen(false)}
                                                onMovePrevRequest={() =>
                                                    setImgIndex((imgIndex + images.length - 1) % images.length)
                                                }
                                                onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images.length)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    
                    <div className="modal fade" tabIndex={-1} id="kt_modal_tourist_place">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add image</h5>
                                    <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr061.svg"
                                            className="svg-icon svg-icon-2x"
                                        />
                                    </div>
                                </div>
                                    <div className="modal-body">
                                        <div>
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Service name</label>
                                            <input type="text"
                                                className="form-control form-control-solid"
                                                value={txtServiceName}
                                                readOnly/>
                                        </div>
                                        
                                        <div className="mt-5">
                                            <label htmlFor="exampleFormControlInput1"
                                                className="form-label">Image url</label>
                                            <textarea className="form-control form-control-solid"
                                                    value={txtImageUrl}
                                                    onChange={(e) => {
                                                        setImageUrl(e.target.value)}
                                                    }/>
                                        </div>
                                    </div>
                                
                                <div className="modal-footer">
                                    <button
                                        id="closeButtonRole"
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal">
                                        Close
                                    </button>
                                    <button type="button"
                                            onClick={createImage}
                                            className="btn btn-primary">
                                        {!loadingButtonSaveImage && 'Save'}
                                        {loadingButtonSaveImage && (
                                            <span className='indicator-progress' style={{display: 'block'}}>Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="warning">You do not have permission to access the site</Alert>
                </Stack>
            )}   
        </>
    )
}

export {ServiceInquiryPage}
