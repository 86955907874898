import { ContactStatusOption, PaymentStatusOption, UserStatusOption } from '../options/userStatusOption';

export function AccountStatusWidget(status: string) {
    switch(status.toUpperCase()){
        case UserStatusOption.New:
            return  <td className='text-end'>
                <span className='badge badge-light-primary'>New</span>
            </td>
        case UserStatusOption.Active:
            return <td className='text-end'>
                <span className='badge badge-light-success'>Active</span>
            </td>
        case UserStatusOption.Blocked:
            return  <td className='text-end'>
                <span className='badge badge-light-warning'>In Blocked</span>
            </td>
        case UserStatusOption.Inactive:
            return  <td className='text-end'>
                <span className='badge badge-light-warning'>InActive</span>
            </td>
         case UserStatusOption.Deleted:
            return  <td className='text-end'>
                <span className='badge badge-light-danger'>Deleted</span>
            </td>
        case ContactStatusOption.Process:
            return  <td className='text-end'>
                <span className='badge badge-light-warning'>In Progress</span>
            </td>
        case ContactStatusOption.Finish:
            return  <td className='text-end'>
                <span className='badge badge-light-success'>Finish</span>
            </td>
        default:
            return  <td className='text-end'>
                <span className='badge badge-light-warning'>In Progress</span>
            </td>
    }
}

export function PaymentStatusWidget(status: string) {
    switch(status.toUpperCase()){
        case PaymentStatusOption.Init:
            return  <td className='text-end'>
                <span className='badge badge-light-primary'>Chưa thanh toán</span>
            </td>
        case PaymentStatusOption.Paid:
            return <td className='text-end'>
                <span className='badge badge-light-success'>Đã thanh toán</span>
            </td>
        default:
            return  <td className='text-end'>
                <span className='badge badge-light-warning'>In Progress</span>
            </td>
    }
}


