import React, {FC, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {ToastMessageOption} from "../../../../../core/options/toastMessageOption";
import {SearchRole, SaveRole, GetRoleGroup, QueryRole} from "../core/service";
import {RoleData, RoleGroupData} from "../core/models";
import {KTSVG} from "../../../../../_metronic/helpers";
import CustomPagination from "../../../../../core/components/data-grid/PaginationPage";
import StripedDataGrid from "../../../../../core/components/data-grid/StripedDataGridPage";
import {GridColumns} from "@mui/x-data-grid";
import {useAuth} from "../../../auth";
import PopupConfirmWidget from "../../../../../core/widgets/PopupConfirmWidget";

const RolePage: FC = () => {
    const {currentUser} = useAuth()
    const UserID = currentUser?.id
    const [loadingButtonSearchRole, setLoadingButtonSearchRole] = useState(false)
    const [loadingButtonSaveRole, setLoadingButtonSaveRole] = useState(false)
    const columns: GridColumns = [
        {field: 'roleID', width: 100, editable: true, renderHeader: () => (<strong>Action</strong>),
            renderCell: (params) => {
            return <>
                <button className="border-0 rounded bg-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_role"
                        onClick={() => {btnPopupRole(params.value)}}>
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='text-primary svg-icon-3' />
                </button>
                <button className="border-0 rounded bg-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_confirm_role"
                        onClick={() =>{setRoleId(params.value)}}>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='text-danger svg-icon-3'/>
                </button>
                </>
            }
        },
        {field: 'roleGroupName', width: 200, editable: true, renderHeader: () => (<strong>Role group</strong>)},
        {field: 'roleName', width: 200, editable: true, renderHeader: () => (<strong>Role name</strong>)},
        {field: 'description', width: 200, editable: true, renderHeader: () => (<strong>Description</strong>)},
        {field: 'isDisable', width: 180, editable: true, renderHeader: () => (<strong>Status</strong>)},
        {field: 'userIDModify', width: 180, editable: true, renderHeader: () => (<strong>User modify</strong>)},
        {field: 'dateTimeModify', width: 220, editable: true, renderHeader: () => (<strong>Date modify</strong>)}
    ];

    const [roleData, setRoleData] = useState<Array<RoleData>>();
    const [txtInputRole, setInputRole] = useState('');
    const [txtRoleId, setRoleId] = useState('');
    const [txtRoleGroupID, setTxtGroupID] = useState('1');
    const [txtRoleName, setTxtRoleName] = useState('');
    const [txtRoleDescription, setTxtRoleDescription] = useState('');
    const [txtValRoleName, setTxtValidateRoleName] = useState('');
    const [txtValRoleDescription, setTxtValRoleDescription] = useState('');
    const [roleGroups, setRoleGroups] = useState<Array<RoleGroupData>>(new Array<RoleGroupData>());
    
    useState(() => {
        GetRoleGroup().then((response) => {
            setRoleGroups(response)
        });
    })

    const validateFormRole = () => {
        let result = true
        if(!txtRoleName){
            result = false
            setTxtValidateRoleName("This field is required")
        }else{
            setTxtValidateRoleName("")
        }
        
        if(!txtRoleDescription){
            result = false
            setTxtValRoleDescription("This field is required")
        }else{
            setTxtValRoleDescription("")
        }
        
        return result
    }
    
    const searchRole = () => {
        setLoadingButtonSearchRole(true)
        SearchRole(txtInputRole).then((response) => {
            if(response){
                setRoleData(response);
            }else {
                toast.info("Data invalid")
            }
            setLoadingButtonSearchRole(false)
        });
    }

    const createRole = () => {
        if(validateFormRole()){
            setLoadingButtonSaveRole(true)
            SaveRole(txtRoleId, 
                    txtRoleGroupID, 
                    txtRoleName, 
                    txtRoleDescription, 
                    false, 
                    UserID).then((response) => {
                if(response){
                    toast.success(ToastMessageOption.Success);
                    let close = document.getElementById('closeButtonRole')
                    if(close){
                        close.click()
                    }
                }else {
                    toast.error(ToastMessageOption.Error);
                }
                setLoadingButtonSaveRole(false)
                searchRole();
            });
        }
    }

    const btnPopupRole = (roleId: string) => {
        if(roleId) {
            QueryRole(roleId).then((response) => {
                setRoleId(response.roleID.toString())
                setTxtGroupID(response.roleGroupID.toString())
                setTxtRoleName(response.roleName)
                setTxtRoleDescription(response.description)
            });
        }else{
            setRoleId('')
            setTxtGroupID('1')
            setTxtRoleName('')
            setTxtRoleDescription('')
        }
    }

    const btnDeleteRole = () =>{
        SaveRole(txtRoleId,
            txtRoleGroupID,
            txtRoleName,
            txtRoleDescription,
            true,
            UserID).then((response) => {
            if(response){
                toast.success(ToastMessageOption.Success);
            }else {
                toast.error(ToastMessageOption.Error);
            }
        });
    }

    return (
        <>
            {PopupConfirmWidget(() => {btnDeleteRole()}, "kt_modal_confirm_role")}
            {/* Role Data */}
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_role_data'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Role</h3>
                    </div>
                </div>
                <div id='kt_role_data' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        <div className='d-flex flex-wrap align-items-center'>
                            <div id='kt_signin_password_edit' className={'flex-row-fluid'}>
                                <div className='row mb-1'> 
                                    <div className='col-lg-4'>
                                        <div>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label">Input role</label>
                                            <input type="email"
                                                   onChange={(e) => {setInputRole(e.target.value)}}
                                                   className="form-control form-control-solid"
                                                   placeholder="role"/>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='mx-auto'>
                                        <button
                                            id='btnReloadSecure'
                                            type='button'
                                            onClick={searchRole}
                                            className='btn btn-primary me-2'
                                        >
                                            {!loadingButtonSearchRole && 'Search'}
                                            {loadingButtonSearchRole && (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <button
                                            id='btnReloadSecure'
                                            type='button'
                                            className='btn btn-success me-2'
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_role"
                                            onClick={() => {btnPopupRole('')}}
                                        >
                                            Create Role
                                        </button>
                                        {/* Model */}
                                        <div className="modal fade" tabIndex={-1} id="kt_modal_role">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Create Role</h5>
                                                        <div
                                                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <KTSVG
                                                                path="/media/icons/duotune/arrows/arr061.svg"
                                                                className="svg-icon svg-icon-2x"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="modal-body">
                                                        <input type="hidden" id="txtRoleId" value={txtRoleId}></input>
                                                        <div>
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label">Role group</label>
                                                            <select className="form-select form-select-solid"
                                                                    value={txtRoleGroupID}
                                                                    onChange={(e) => {setTxtGroupID(e.target.value)}}
                                                                    aria-label="Select example">
                                                                {
                                                                    roleGroups.map((roleGroup) => (
                                                                    <option key={roleGroup.roleGroupID} value={roleGroup.roleGroupID}>{roleGroup.roleGroupName}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mt-5">
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label">Role name</label>
                                                            <input type="text"
                                                                   value={txtRoleName}
                                                                   onChange={(e) => {setTxtRoleName(e.target.value)}}
                                                                   className="form-control form-control-solid"
                                                                   placeholder="role name"/>
                                                            <span style={{ color: "red" }}>{txtValRoleName}</span>
                                                        </div>
                                                        <div className="mt-5">
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label">Description</label>
                                                            <textarea className="form-control form-control-solid"
                                                                      value={txtRoleDescription}
                                                                      onInput={(e) => setTxtRoleDescription((e.target as HTMLInputElement).value)}
                                                                      placeholder="description"/>
                                                            <span style={{ color: "red" }}>{txtValRoleDescription}</span>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            id="closeButtonRole"
                                                            type="button"
                                                            className="btn btn-light"
                                                            data-bs-dismiss="modal">
                                                            Close
                                                        </button>
                                                        <button type="button"
                                                                onClick={createRole}
                                                                className="btn btn-primary">
                                                            {!loadingButtonSaveRole && 'Save'}
                                                            {loadingButtonSaveRole && (
                                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {roleData && (
                                    <div className="mt-10" style={{ height: 400, width: '100%' }}>
                                        <StripedDataGrid
                                            loading={false}
                                            rows={roleData}
                                            columns={columns}
                                            getRowClassName={(params) =>
                                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                            }
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            components={{
                                                Pagination: CustomPagination,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {RolePage}

